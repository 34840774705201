<template>
  <div class="page-wrapper flex flex-col justify-center">
    <div class="flex justify-center py-3 create-logo-container w-full">
      <img
        :src="require('../../../../../assets/images/fcLOgo.svg')"
        alt="Fresh Clinics"
        width="251"
        height="56"
        @click="gotoFcSite()"
      />
    </div>

    <div class="back-button-wrapper">
      <div class="flex items-center justify-start cursor-pointer py-4 w-64">
        <a
          style="font-weight: 400"
          class="flex justify-start items-center -ml-2 mb-3"
          @click="login"
        >
          <chevron-left-icon
            class="custom-class"
            size="1.5x"
          ></chevron-left-icon>
          Back</a
        >
      </div>
    </div>

    <div class="create-account-container">
      <!-- <div class="form-heading">
        <p>Create Account</p>
      </div> -->
      <div
        id="form"
        data-tf-widget="fBynTcKD"
        style="width: 100%; height: 100%"
      ></div>
    </div>
  </div>
</template>

<script>
import FranchiseStep from "../../franchise/FranchiseStep";
import ClinicStep from "../../clinic/ClinicStep";
import DoctorStep from "../../doctor/DoctorStep";
import NurseStep from "../../nurse/NurseStep";
import vSelect from "vue-select";
import moment from "moment";
import { ChevronLeftIcon } from "vue-feather-icons";

import { createWidget } from "@typeform/embed";
import "@typeform/embed/build/css/widget.css";

export default {
  name: "DoctorRegister",
  components: {
    FranchiseStep,
    ClinicStep,
    DoctorStep,
    NurseStep,
    "v-select": vSelect,
    ChevronLeftIcon,
  },
  data() {
    return {
      //   userData:{companyName:''},
      userTypeOptions: [
        { label: "Franchise", value: "franchise" },
        { label: "Clinic", value: "clinic" },
        { label: "Doctor", value: "doctor" },
        { label: "Nurse", value: "nurse" },
      ],
      hasDoneComplianceCheck: false,
      selected: "franchise",
      tabIndex: 0,
      isDisabled: true,
      registerType: "doctor",
      flashMessage: "",
      showDismissibleAlert: false,
      refEmail: "",
      refType: "",
      refCode: "",
      clinicId: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirm_password: "",
      isTermsConditionAccepted: false,
      isDoctorAgreementAccepted: false,
      isClinicAgreementAccepted: false,
      validationKeys: {
        showRestrictionImposeError: false,
        showAggreementOnAhpraRegistrationChangedError: false,
        showAggreementOnIndemnityRegistrationChangedError: false,
        showRnOrEnError: false,
        showToxinTreatmentError: false,
        showFillerTreatmentError: false,
        showHasCompletedHyalaseTraningInLastTwelveMonth: false,
        showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth: false,
        showHasReadClinicalStandardsDocumentsError: false,
        showhasImmunityToHepatitisBError: false,
        showHasConfirmedDeclarationsError: false,
        showCanSendEmailError: false,
      },
      validationKeysClinic: {
        showIsResidentialAddressError: false,
        showIntendToSecureS4MedicationError: false,
        showHaveAdrenalineAndHyalaseInClinicError: false,
      },
      userData: {
        status: "Active",
        companyName: "", //franchise
        clinicName: "",
        address: {
          displayAddress: "",
          state: "",
          suburb: "",
          postCode: "",
          coordinates: [],
          geometry: {
            type: "",
            coordinates: [],
          },
          addressData: [],
        },
        isNurseInjecter: false,
        abn: "",

        postalAddress: "",
        bestContactName: "",
        bestContactEmail: "",
        bestContactNumber: "",
        invoiceEmail: "",
        wantCentralisebilling: false,
        franchiseBusinessName: "",

        preferredName: "", //doctor
        contactNumber: "",
        prescriberNumber: "",
        ahpraRegistrationNumber: "",
        ahpraExiryDate: "",
        indemnityInsuranceProvider: "",
        indemnityInsuranceNumber: "",
        indemnityInsuranceExpiry: "",
        ahpraCertificate: "",
        insuranceCertificate: "",
        // signature: "",

        profileImage: "",
        nurseCosmeticExperience: "",

        identityDocument: {
          front: "",
          back: "",
        },
        dateOfBirth: "",
        instagram: "",
        facebook: "",
        website: "",
        clinicAddress1: "",
        city: "",
        stateOrRegion: "",
        photoId: "",
        restrictionImposed: "",
        aggreementOnAhpraRegistrationChanged: "",
        aggreementOnIndemnityRegistrationChanged: "",
        rnOrEn: "",
        expericedYearInRnOrEn: "",
        areasForRnOrEn: "",
        cosmeticInjectorExperienceYears: "",
        cosmeticInjectableCourses: "",
        trainingCertificate: "",
        additionalTeachingInInjectables: "",
        averageHourInCosmeticPracticePerWeek: "",
        planForAverageHourInCosmeticPracticePerWeek: "",
        numberOfPatientsTreated: "",
        confidentAreasForToxinTreatment: {
          Glabella: false,
          Frontalis: false,
          "Lateral Orbicularis Oculi": false,
          "Lip eversion": false,
          "Lipstick lines": false,
          "Brow Lift": false,
          "Nasalis (bunny lines)": false,
          "LLSAN (gummy smile)": false,
          Mentalis: false,
          DAO: false,
          Masseters: false,
          Hyperhidrosis: false,
          Platysma: false,
        },
        confidentAreasForFillerTreatment: {
          "Lip filler": false,
          Chin: false,
          "Tear Trough": false,
          Cheeks: false,
          Jawline: false,
          "Lip-stick lines": false,
          NLF: false,
          "Marionette Lines": false,
        },
        areasNotConfidentIn: "",
        additionalAreaOfPractice: "",
        cv: "",
        hasCompletedHyalaseTraningInLastTwelveMonth: "",
        hyalaseOrComplicationManagementCertificate: "",
        hyalaseTrainingExpirationDate: "",
        hasCompletedBasicLifeSupportTrainingInLastTwelveMonth: "",
        blsCertificate: "",
        blsTrainingExpirationDate: "",
        vaccinationCertificate: "",
        hasImmunityToHepatitisB: "",
        hasReadClinicalStandardsDocuments: "",
        hasConfirmedDeclarations: "",
        canSendEmail: "",
        isResidentialAddress: "",
        haveAdrenalineAndHyalaseInClinic: "",
        intendToSecureS4Medication: "",
        abnCertificate: "",
        councilPermits: [],
        poisonsCertificate: [],
        clinicOpeningHours: {
          mondays: "",
          tuesdays: "",
          wednesdays: "",
          thursdays: "",
          fridays: "",
          saturdays: "",
          sundays: "",
        },
        clinicFrontPhoto: [],
        clinicFloorPhoto: [],
        clinicTreatmentBedPhoto: [],
        clinicDrawingUpAreaPhoto: [],
        clinicFridgePhoto: [],
        clinicDrugStoragePhoto: [],
        clinicBinsPhoto: [],
        clinicRoomLockPhoto: [],
        clinicEpipenAndHyalasePhoto: [],
      },
      passwordType: "password",
      passwordIcon: "icon icon-eye-off",
      confirmPasswordType: "password",
      confirmPasswordIcon: "icon icon-eye-off",
      checkAddress: "",
    };
  },
  created() {
    let ref = this.$route.query.ref;
    if (typeof ref !== "undefined") {
      this.refCode = ref;
      this.getInvitationDetail(ref);
    }
    const createWidgeFunc = () => {
      if (document.querySelector("#form")) {
        return createWidget(process.env.VUE_APP_TYPEFORM_KEY, {
          container: document.querySelector("#form"),
        });
      }
      setTimeout(function () {
        createWidgeFunc();
      }, 1000);
    };
    createWidgeFunc();
  },
  methods: {
    gotoFcSite() {
      window.open(process.env.VUE_APP_FRESHCLINICS_V2_SITE);
    },
    login(event) {
      this.displayLoader();
      this.$router.go(-1);
      event.preventDefault();
    },
    changeHasDoneComplianceCheck(val) {
      this.hasDoneComplianceCheck = val;
    },
    redirect(url) {
      this.displayLoader();
      window.location.href = url;
    },
    register() {
      this.displayLoader();
      this.$router.push("/login");
    },
    displayLoader() {
      this.$vs.loading();
      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
    },
    showHidePassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.passwordType === "password"
          ? (this.passwordType = "text")
          : (this.passwordType = "password");
        this.passwordIcon === "icon icon-eye-off"
          ? (this.passwordIcon = "icon icon-eye")
          : (this.passwordIcon = "icon icon-eye-off");
      }
      return true;
    },
    showHideConfirmPassword(event) {
      if (
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye-off null icon-no-border" ||
        event.target.className ===
          "vs-icon notranslate icon-scale icon-inputx notranslate vs-input--icon feather icon icon-eye null icon-no-border"
      ) {
        this.confirmPasswordType === "password"
          ? (this.confirmPasswordType = "text")
          : (this.confirmPasswordType = "password");
        this.confirmPasswordIcon === "icon icon-eye-off"
          ? (this.confirmPasswordIcon = "icon icon-eye")
          : (this.confirmPasswordIcon = "icon icon-eye-off");
      }
      return true;
    },
    goToTermsAndConditions() {
      let routeData;
      let userType = this.selected;
      if (userType === "franchise") {
        routeData = this.$router.resolve({
          name: "TermsAndConditions",
          params: { slug: "terms-and-condition-franchise" },
        });
      }
      if (userType === "clinic") {
        routeData = this.$router.resolve({
          name: "TermsAndConditions",
          params: { slug: "terms-and-condition-clinic" },
        });
      }
      if (userType === "doctor") {
        routeData = this.$router.resolve({
          name: "TermsAndConditions",
          params: { slug: "terms-and-condition-doctor" },
        });
      }
      if (userType === "nurse") {
        routeData = this.$router.resolve({
          name: "TermsAndConditions",
          params: { slug: "terms-and-condition-nurse" },
        });
      }
      window.open(routeData.href, "_blank");
    },
    goToDoctorAgreement() {
      let routeData = this.$router.resolve({
        name: "DoctorAgreement",
        params: { slug: "doctor-agreement" },
      });
      window.open(routeData.href, "_blank");
    },
    goToClinicAgreement() {
      let routeData = this.$router.resolve({
        name: "ClinicAgreement",
        params: { slug: "clinic-agreement" },
      });
      window.open(routeData.href, "_blank");
    },
    goToPrivacyPolicy() {
      let routeData;
      let userType = this.selected;
      if (userType === "franchise") {
        routeData = this.$router.resolve({
          name: "PrivacyPolicy",
          params: { slug: "privacy-policy-franchise" },
        });
      }
      if (userType === "clinic") {
        routeData = this.$router.resolve({
          name: "PrivacyPolicy",
          params: { slug: "privacy-policy-clinic" },
        });
      }
      if (userType === "doctor") {
        routeData = this.$router.resolve({
          name: "PrivacyPolicy",
          params: { slug: "privacy-policy-doctor" },
        });
      }
      if (userType === "nurse") {
        routeData = this.$router.resolve({
          name: "PrivacyPolicy",
          params: { slug: "privacy-policy-nurse" },
        });
      }
      window.open(routeData.href, "_blank");
    },
    async switchTab(tabIndex) {
      let self = this;
      if (tabIndex == 1) {
        self.tabIndex = 1;
        const valid = await this.$validator.validate();
        if (!valid) {
          // this.isDisabled = null
          // this.flashMessage = 'Complete step 1 first'
          // this.showDismissibleAlert = true
          this.$vs.notify({
            title: "Complete Step",
            text: "Complete step 1 first",
            color: "danger",
          });
          this.tabIndex = 0;
        }
      }
    },
    //check here
    async nextTab() {
      this.tabIndex = 0;

      const valid = await this.$validator.validate();
      if (this.refCode) {
        if (this.refEmail !== this.email) {
          this.errors.add({
            field: "email",
            msg: "Email must be referred email",
          });
          return false;
        }
        if (this.refType !== this.selected) {
          this.errors.add({
            field: "type",
            msg: "You must select " + this.refType,
          });
          return false;
        } else {
          this.errors.remove("type");
        }
      }
      if (!valid) {
        // this.flashMessage = 'Complete step 1 first'
        // this.showDismissibleAlert = true
        this.$vs.notify({
          title: "Complete Step",
          text: "Complete step 1 first",
          color: "danger",
        });
        return false;
      }

      this.$store
        .dispatch("clinic/checkEmail", this.email)
        .then((res) => {
          this.tabIndex = 1;
        })
        .catch((err) => {
          if (err.status === 422) {
            this.errors.add({
              field: "email",
              msg: err.data.message,
            });
          } else {
            this.$vs.notify({
              title: "Register Attempt",
              text: "Server Error",
              color: "warning",
            });
          }
        });
    },
    async registerUserJWt() {
      let self = this;
      // If form is not validated or user is already login return
      // if (!this.validateForm || !this.checkLogin()) return
      //const valid = await this.$validator.validate()
      let flag = false;

      const valid = await this.$refs.stepTwoRef.$validator.validate();
      console.log("###################### valid", this.$refs.stepTwoRef.errors);

      if (!valid) {
        flag = true;
      }

      if (this.selected === "clinic") {
        if (self.userData.bestContactEmail === "") {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "bestContactEmail",
            msg: "Enter valid email address.",
          });
        } else {
          flag = false;
          this.$refs.stepTwoRef.errors.remove("bestContactEmail");
        }

        if (self.userData.invoiceEmail === "") {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "invoiceEmail",
            msg: "Enter valid email address.",
          });
        } else {
          flag = false;
          this.$refs.stepTwoRef.errors.remove("invoiceEmail");
        }
      }

      if (this.selected === "clinic") {
        if (
          !self.userData.address.coordinates[0] ||
          self.userData.address.addressData[0].checkDisplayAddress !=
            self.userData.address.displayAddress
        ) {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "displayAddress",
            msg: "Enter valid address.",
          });
        } else {
          this.$refs.stepTwoRef.errors.remove("displayAddress");
        }

        if (
          this.userData.address.addressData &&
          this.userData.address.addressData.length > 0 &&
          this.selected === "franchise"
        ) {
          this.userData.address.addressData.forEach(function (address) {
            if (address.types[0] === "administrative_area_level_2") {
              self.userData.address.suburb = address.long_name;
            }
            if (address.types[0] === "administrative_area_level_1") {
              self.userData.address.state = address.long_name;
            }
            if (address.types[0] === "postal_code") {
              self.userData.address.postCode = address.long_name;
            }
          });
        }
      }

      if (this.selected === "franchise") {
        if (
          !self.userData.address.coordinates[0] ||
          self.userData.address.addressData[0].checkDisplayAddress !=
            self.userData.address.displayAddress
        ) {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "displayAddress",
            msg: "Enter valid address.",
          });
        } else {
          this.$refs.stepTwoRef.errors.remove("displayAddress");
        }

        if (
          this.userData.address.addressData &&
          this.userData.address.addressData.length > 0 &&
          this.selected === "franchise"
        ) {
          this.userData.address.addressData.forEach(function (address) {
            if (address.types[0] === "administrative_area_level_2") {
              self.userData.address.suburb = address.long_name;
            }
            if (address.types[0] === "administrative_area_level_1") {
              self.userData.address.state = address.long_name;
            }
            if (address.types[0] === "postal_code") {
              self.userData.address.postCode = address.long_name;
            }
          });
        }
      }

      if (this.selected === "doctor") {
        if (
          moment(new Date(this.userData.dateOfBirth)).format("YYYY-MM-DD") >
          moment().subtract(18, "years").format("YYYY-MM-DD")
        ) {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "dateOfBirth",
            msg: "Your age must be 18 years or above",
          });
        } else {
          this.$refs.stepTwoRef.errors.remove("dateOfBirth");
        }

        if (typeof self.userData.address !== "string") {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "address",
            msg: "Enter valid address. Must choose from dropdown suggestion.",
          });
        } else {
          this.$refs.stepTwoRef.errors.remove("address");
        }

        if (this.userData.identityDocument.front === "") {
          this.$refs.stepTwoRef.errors.add({
            field: "identityDocumentFront",
            msg: "Identity document front is required.",
          });
          document.getElementById("identityDocumentFrontId").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("identityDocumentFrontId").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("identityDocumentFront");
        }

        if (this.userData.identityDocument.back === "") {
          this.$refs.stepTwoRef.errors.add({
            field: "identityDocumentBack",
            msg: "Identity document back is required.",
          });
          document.getElementById("identityDocumentBackId").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("identityDocumentBackId").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("identityDocumentBack");
        }
      }

      if (this.selected === "nurse") {
        if (
          moment(new Date(this.userData.hyalaseTrainingExpirationDate)).format(
            "YYYY-MM-DD"
          ) > moment().add(1, "years").format("YYYY-MM-DD")
        ) {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "hyalaseTrainingExpirationDate",
            msg: "Hyalase Training Expiration date cannot be greater than 1 year",
          });
        } else {
          this.$refs.stepTwoRef.errors.remove("hyalaseTrainingExpirationDate");
        }

        if (
          moment(new Date(this.userData.blsTrainingExpirationDate)).format(
            "YYYY-MM-DD"
          ) > moment().add(1, "years").format("YYYY-MM-DD")
        ) {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "blsTrainingExpirationDate",
            msg: "BLS Training Expiration date cannot be greater than 1 year",
          });
        } else {
          this.$refs.stepTwoRef.errors.remove("blsTrainingExpirationDate");
        }

        // if (this.userData.photoId == "") {
        //   this.$refs.stepTwoRef.errors.add({
        //     field: "photoId",
        //     msg: "Photo ID is required",
        //   });
        //   flag = true;
        //   document.getElementById("photoId").style.borderColor = "#ea5455";
        // } else {
        //   document.getElementById("photoId").style.borderColor = "#cccccc";
        //   this.$refs.stepTwoRef.errors.remove("photoId");
        // }

        if (this.userData.restrictionImposed === "") {
          flag = true;
          this.validationKeys.showRestrictionImposeError = true;
        } else {
          this.validationKeys.showRestrictionImposeError = false;
        }

        // if (this.userData.canSendEmail === "") {
        //   flag = true;
        //   this.validationKeys.showCanSendEmailError = true;
        // } else {
        //   this.validationKeys.showCanSendEmailError = false;
        // }

        // if (this.userData.hasConfirmedDeclarations === "") {
        //   flag = true;
        //   this.validationKeys.showHasConfirmedDeclarationsError = true;
        // } else {
        //   this.validationKeys.showHasConfirmedDeclarationsError = false;
        // }

        // if (this.userData.hasReadClinicalStandardsDocuments === "") {
        //   flag = true;
        //   this.validationKeys.showHasReadClinicalStandardsDocumentsError = true;
        // } else {
        //   this.validationKeys.showHasReadClinicalStandardsDocumentsError = false;
        // }

        if (this.userData.hasImmunityToHepatitisB === "") {
          flag = true;
          this.validationKeys.showhasImmunityToHepatitisBError = true;
        } else {
          this.validationKeys.showhasImmunityToHepatitisBError = false;
        }

        if (
          this.userData
            .hasCompletedBasicLifeSupportTrainingInLastTwelveMonth === ""
        ) {
          flag = true;
          this.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = true;
        } else {
          this.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false;
        }

        if (this.userData.aggreementOnAhpraRegistrationChanged === "") {
          flag = true;
          this.validationKeys.showAggreementOnAhpraRegistrationChangedError = true;
        } else {
          this.validationKeys.showAggreementOnAhpraRegistrationChangedError = false;
        }

        if (this.userData.aggreementOnIndemnityRegistrationChanged === "") {
          flag = true;
          this.validationKeys.showAggreementOnIndemnityRegistrationChangedError = true;
        } else {
          this.validationKeys.showAggreementOnIndemnityRegistrationChangedError = false;
        }

        if (this.userData.hasCompletedHyalaseTraningInLastTwelveMonth === "") {
          flag = true;
          this.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = true;
        } else {
          this.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false;
        }

        if (this.userData.rnOrEn === "") {
          flag = true;
          this.validationKeys.showRnOrEnError = true;
        } else {
          this.validationKeys.showRnOrEnError = false;
        }

        var hasTrueKeys = Object.keys(
          this.userData.confidentAreasForToxinTreatment
        ).some((k) => this.userData.confidentAreasForToxinTreatment[k]);
        if (!hasTrueKeys) {
          flag = true;
          this.validationKeys.showToxinTreatmentError = true;
        } else {
          this.validationKeys.showToxinTreatmentError = false;
        }

        let checkFillerTrueKeys = Object.keys(
          this.userData.confidentAreasForFillerTreatment
        ).some((k) => this.userData.confidentAreasForFillerTreatment[k]);
        if (!checkFillerTrueKeys) {
          flag = true;
          this.validationKeys.showFillerTreatmentError = true;
        } else {
          this.validationKeys.showFillerTreatmentError = false;
        }

        if (this.userData.cv == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "cv",
            msg: "CV is required",
          });
          flag = true;
          document.getElementById("cvId").style.borderColor = "#ea5455";
        } else {
          document.getElementById("cvId").style.borderColor = "#cccccc";
          this.$refs.stepTwoRef.errors.remove("cv");
        }

        if (this.userData.hyalaseOrComplicationManagementCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "hyalaseOrComplicationManagementCertificate",
            msg: "Hyalase or complication management certificate is required",
          });
          flag = true;
          document.getElementById(
            "hyalaseManagenentCertificateId"
          ).style.borderColor = "#ea5455";
        } else {
          document.getElementById(
            "hyalaseManagenentCertificateId"
          ).style.borderColor = "#cccccc";
          this.$refs.stepTwoRef.errors.remove(
            "hyalaseOrComplicationManagementCertificate"
          );
        }

        if (this.userData.blsCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "blsCertificate",
            msg: "BLS certificate is required",
          });
          flag = true;
          document.getElementById("blsCertificateId").style.borderColor =
            "#ea5455";
        } else {
          document.getElementById("blsCertificateId").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("blsCertificate");
        }

        if (this.userData.vaccinationCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "vaccinationCertificate",
            msg: "Vaccination certificate is required",
          });
          flag = true;
          document.getElementById(
            "vaccinationCertificateId"
          ).style.borderColor = "#ea5455";
        } else {
          document.getElementById(
            "vaccinationCertificateId"
          ).style.borderColor = "#cccccc";
          this.$refs.stepTwoRef.errors.remove("vaccinationCertificate");
        }
      }

      if (this.selected === "nurse") {
        if (
          moment(new Date(this.userData.dateOfBirth)).format("YYYY-MM-DD") >
          moment().subtract(18, "years").format("YYYY-MM-DD")
        ) {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "dateOfBirth",
            msg: "Your age must be 18 years or above",
          });
        } else {
          this.$refs.stepTwoRef.errors.remove("dateOfBirth");
        }

        if (this.userData.ahpraRegistrationNumber === "") {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "ahpraRegistrationNumber",
            msg: "The AHPRA registration number is required",
          });
        } else {
          flag = false;
          this.$refs.stepTwoRef.errors.remove("ahpraRegistrationNumber");
        }

        if (this.userData.ahpraRegistrationNumber === "") {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "ahpraRegistrationNumber",
            msg: "The AHPRA registration number is required",
          });
        } else {
          flag = false;
          this.$refs.stepTwoRef.errors.remove("ahpraRegistrationNumber");
        }

        if (this.userData.indemnityInsuranceNumber === "") {
          flag = true;
          this.$refs.stepTwoRef.errors.add({
            field: "indemnityInsuranceNumber",
            msg: "The indemnity insurance number is required",
          });
        } else {
          flag = false;
          this.$refs.stepTwoRef.errors.remove("indemnityInsuranceNumber");
        }

        if (this.userData.hasReadClinicalStandardsDocuments === "") {
          flag = true;
          this.validationKeys.showHasReadClinicalStandardsDocumentsError = true;
        } else {
          this.validationKeys.showHasReadClinicalStandardsDocumentsError = false;
        }

        if (this.userData.hasConfirmedDeclarations === "") {
          flag = true;
          this.validationKeys.showHasConfirmedDeclarationsError = true;
        } else {
          this.validationKeys.showHasConfirmedDeclarationsError = false;
        }

        if (this.userData.canSendEmail === "") {
          flag = true;
          this.validationKeys.showCanSendEmailError = true;
        } else {
          this.validationKeys.showCanSendEmailError = false;
        }
      }

      if (this.selected === "doctor" || this.selected === "nurse") {
        if (this.userData.ahpraCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "aphraCertificate",
            msg: "APHRA Certificate is required",
          });
          flag = true;
          document.getElementById("ahpraId").style.borderColor = "#ea5455";
        } else {
          document.getElementById("ahpraId").style.borderColor = "#cccccc";
          this.$refs.stepTwoRef.errors.remove("aphraCertificate");
        }

        if (this.userData.insuranceCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "insuranceCertificate",
            msg: "Insurance Certificate is required",
          });
          document.getElementById("insuranceId").style.borderColor = "#ea5455";
          flag = true;
        } else {
          document.getElementById("insuranceId").style.borderColor = "#cccccc";
          this.$refs.stepTwoRef.errors.remove("insuranceCertificate");
        }

        // if (this.userData.signature == "" && this.selected === "doctor") {
        //   this.$refs.stepTwoRef.errors.add({
        //     field: "electronicSignature",
        //     msg: "Electronic Signature is required"
        //   });
        //   document.getElementById("signatureId").style.borderColor = "#ea5455";
        //   flag = true;
        // } else if (this.selected === "doctor") {
        //   document.getElementById("signatureId").style.borderColor = "#cccccc";
        //   this.$refs.stepTwoRef.errors.remove("electronicSignature");
        // }
      }

      if (this.selected === "clinic" && !this.hasDoneComplianceCheck) {
        if (this.userData.isResidentialAddress === "") {
          flag = true;
          this.validationKeysClinic.showIsResidentialAddressError = true;
        } else {
          this.validationKeysClinic.showIsResidentialAddressError = false;
        }

        if (this.userData.intendToSecureS4Medication === "") {
          flag = true;
          this.validationKeysClinic.showIntendToSecureS4MedicationError = true;
        } else {
          this.validationKeysClinic.showIntendToSecureS4MedicationError = false;
        }

        if (this.userData.haveAdrenalineAndHyalaseInClinic === "") {
          flag = true;
          this.validationKeysClinic.showHaveAdrenalineAndHyalaseInClinicError = true;
        } else {
          this.validationKeysClinic.showHaveAdrenalineAndHyalaseInClinicError = false;
        }

        if (this.userData.abnCertificate == "") {
          this.$refs.stepTwoRef.errors.add({
            field: "abnCertificate",
            msg: "ABN Certificate is required",
          });
          document.getElementById("abnCertificateId").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("abnCertificateId").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("abnCertificate");
        }

        if (this.userData.clinicFrontPhoto.length === 0) {
          this.$refs.stepTwoRef.errors.add({
            field: "clinicFrontPhoto",
            msg: "Clinic Front Photo is required",
          });
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("clinicFrontPhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("clinicFrontPhoto");
        }

        if (this.userData.clinicFloorPhoto.length === 0) {
          this.$refs.stepTwoRef.errors.add({
            field: "clinicFloorPhoto",
            msg: "Clinic Floor Photo is required",
          });
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("clinicFloorPhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("clinicFloorPhoto");
        }

        if (this.userData.clinicTreatmentBedPhoto.length === 0) {
          this.$refs.stepTwoRef.errors.add({
            field: "clinicTreatmentBedPhoto",
            msg: "Clinic Treatment Bed Photo is required",
          });
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("clinicTreatmentBedPhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("clinicTreatmentBedPhoto");
        }

        if (this.userData.clinicDrawingUpAreaPhoto.length === 0) {
          this.$refs.stepTwoRef.errors.add({
            field: "clinicDrawingUpAreaPhoto",
            msg: "Clinic Drawing Up Area Photo is required",
          });
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#ea5455";
          flag = true;
        } else {
          document.getElementById(
            "clinicDrawingUpAreaPhoto"
          ).style.borderColor = "#cccccc";
          this.$refs.stepTwoRef.errors.remove("clinicDrawingUpAreaPhoto");
        }

        if (this.userData.clinicFridgePhoto.length === 0) {
          this.$refs.stepTwoRef.errors.add({
            field: "clinicFridgePhoto",
            msg: "Clinic Fridge Photo is required",
          });
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("clinicFridgePhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("clinicFridgePhoto");
        }

        if (this.userData.clinicDrugStoragePhoto.length === 0) {
          this.$refs.stepTwoRef.errors.add({
            field: "clinicDrugStoragePhoto",
            msg: "Clinic Drug Storage Photo is required",
          });
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("clinicDrugStoragePhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("clinicDrugStoragePhoto");
        }

        if (this.userData.clinicBinsPhoto.length === 0) {
          this.$refs.stepTwoRef.errors.add({
            field: "clinicBinsPhoto",
            msg: "Clinic Bins Photo is required",
          });
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#ea5455";
          flag = true;
        } else {
          document.getElementById("clinicBinsPhoto").style.borderColor =
            "#cccccc";
          this.$refs.stepTwoRef.errors.remove("clinicBinsPhoto");
        }
      }

      if (flag) return false;

      if (this.selected === "clinic" || this.selected === "franchise") {
        self.userData.address.geometry.type = "Point";
        self.userData.address.geometry.coordinates =
          self.userData.address.coordinates;

        // delete self.userData.address.addressData;
        // delete self.userData.address.coordinates;
      }
      if (this.selected === "nurse") {
        delete self.userData.address;
      }
      let payload = {};
      payload = {
        userDetails: {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password,
          userType: this.selected,
        },
        notify: this.$vs.notify,
      };
      if (this.refCode) {
        payload.userDetails.associatedClinicIds = [
          {
            clinicId: this.clinicId,
            isActive: true,
          },
        ];
      }
      payload.userDetails = { ...payload.userDetails, ...this.userData };

      self.$vs.loading();

      this.$store
        .dispatch("clinic/registerUserJWT", payload)
        .then((res) => {
          self.$vs.loading.close();
          this.$vs.notify({
            title:
              self.selected.charAt(0).toUpperCase() +
              self.selected.slice(1) +
              " Registration",
            text:
              self.selected.charAt(0).toUpperCase() +
              self.selected.slice(1) +
              " Registered Successfully.",
            color: "success",
          });
        })
        .catch((err) => {
          self.$vs.loading.close();
          const errorTitle =
            err.data && err.data.title ? err.data.title : "Error";
          const errorMessage =
            err.data && err.data.message
              ? err.data.message
              : "Oops ! something went wrong.";

          this.flashMessage = errorMessage;
          this.showDismissibleAlert = true;
          this.$vs.notify({
            title: errorTitle,
            text: errorMessage,
            color: "warning",
          });
        });
    },
    getInvitationDetail(ref) {
      let self = this;
      this.$store
        .dispatch("clinic/getInvitationDetail", ref)
        .then((res) => {
          self.email = res.data.data.email;
          self.refEmail = res.data.data.email;
          self.clinicId = res.data.data.clinicId;
          self.refType = res.data.data.type.toLowerCase();
          self.selected = res.data.data.type.toLowerCase();
        })
        .catch((err) => {});
    },
  },
  computed: {
    validateForm() {
      let userType = this.selected;
      if (userType === "doctor")
        return (
          this.isTermsConditionAccepted === true &&
          this.isDoctorAgreementAccepted === true
        );
      else if (userType === "clinic")
        return (
          this.isTermsConditionAccepted === true &&
          this.isClinicAgreementAccepted === true
        );
      else return this.isTermsConditionAccepted === true;
    },
  },
  watch: {
    "userData.bestContactEmail": function (newVal, oldVal) {
      if (newVal !== "") {
        this.$refs.stepTwoRef.errors.remove("bestContactEmail");
      }
    },
    "userData.invoiceEmail": function (newVal, oldVal) {
      if (newVal !== "") {
        this.$refs.stepTwoRef.errors.remove("invoiceEmail");
      }
    },
    "userData.ahpraRegistrationNumber": function (newVal, oldVal) {
      if (newVal !== "") {
        this.$refs.stepTwoRef.errors.remove("ahpraRegistrationNumber");
      }
    },
    "userData.indemnityInsuranceNumber": function (newVal, oldVal) {
      if (newVal !== "") {
        this.$refs.stepTwoRef.errors.remove("indemnityInsuranceNumber");
      }
    },
    "userData.isResidentialAddress": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeysClinic.showIsResidentialAddressError = false;
      }
    },
    "userData.intendToSecureS4Medication": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeysClinic.showIntendToSecureS4MedicationError = false;
      }
    },
    "userData.haveAdrenalineAndHyalaseInClinic": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeysClinic.showHaveAdrenalineAndHyalaseInClinicError = false;
      }
    },
    "userData.restrictionImposed": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showRestrictionImposeError = false;
      }
    },
    "userData.aggreementOnAhpraRegistrationChanged": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showAggreementOnAhpraRegistrationChangedError = false;
      }
    },
    "userData.aggreementOnIndemnityRegistrationChanged": function (
      newVal,
      oldVal
    ) {
      if (newVal !== "") {
        this.validationKeys.showAggreementOnIndemnityRegistrationChangedError = false;
      }
    },
    "userData.rnOrEn": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showRnOrEnError = false;
      }
    },
    "userData.hasCompletedHyalaseTraningInLastTwelveMonth": function (
      newVal,
      oldVal
    ) {
      if (newVal !== "") {
        this.validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth = false;
      }
    },
    "userData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth": function (
      newVal,
      oldVal
    ) {
      if (newVal !== "") {
        this.validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth = false;
      }
    },
    "userData.hasReadClinicalStandardsDocuments": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showHasReadClinicalStandardsDocumentsError = false;
      }
    },
    "userData.hasImmunityToHepatitisB": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showhasImmunityToHepatitisBError = false;
      }
    },
    "userData.hasConfirmedDeclarations": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showHasConfirmedDeclarationsError = false;
      }
    },
    "userData.canSendEmail": function (newVal, oldVal) {
      if (newVal !== "") {
        this.validationKeys.showCanSendEmailError = false;
      }
    },
    "userData.confidentAreasForToxinTreatment": {
      handler: function (newVal, oldVal) {
        const checkTrueKeys = Object.keys(newVal).some((k) => newVal[k]);
        if (checkTrueKeys) this.validationKeys.showToxinTreatmentError = false;
      },
      deep: true,
    },
    "userData.confidentAreasForFillerTreatment": {
      handler: function (newVal, oldVal) {
        const checkTrueKeys = Object.keys(newVal).some((k) => newVal[k]);
        if (checkTrueKeys) this.validationKeys.showFillerTreatmentError = false;
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.linkClass {
  z-index: 10000;
}
.style-changer {
  padding-bottom: 0.2rem !important;
}
.btn-upload-all {
  display: none;
}
.hei {
  min-height: 100vh;
  height: 100%;
}
.c {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.space {
  width: 100%;
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /*word-spacing: 30px;*/
}

.create-account-container {
  height: 100%;
  width: 50%;
  margin-right: auto;
  margin-left: auto;

  border-radius: 6px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);

  .con-tab {
    padding-bottom: 14px;
  }

  .con-slot-tabs {
    margin-top: 1rem;
  }
}

.back-button-wrapper {
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 480px) {
  .create-account-container {
    width: 100% !important;
    height: calc(100vh - 128px);
  }
  .page-wrapper {
    height: auto !important;
  }
  .back-button-wrapper {
    width: 100%;
  }
  .create-logo-container {
    display: none !important;
  }
}
.page-wrapper {
  height: 100vh;
}

.form-heading {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0e4333;
}

.create-logo-container {
  border-bottom: 2px solid #ebe9f1;
}
</style>
