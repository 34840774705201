<template>
  <div>
    <br />
    <div>
      <div
        class="mb-4 img-wrapper"
        v-if="userData.profileImage"
        style="margin: auto"
      >
        <!-- <vs-avatar :src="userData.profileImage" size="145px" /> -->
        <img :src="userData.profileImage" class="img-fluid" alt />
      </div>
      <div
        class="mb-4"
        v-else
        style="margin: auto; width: 50%; display: flex; justify-content: center"
      >
        <vs-avatar
          src="https://freshclinics-staging.s3.ap-southeast-2.amazonaws.com/undefined/tjl5fnug0jhy1588583500891.png"
          size="110px"
        />
      </div>
      <div
        id="profileImageUpload"
        style="text-align: center; position: relative"
      >
        <label
          class="image-upload profile-image-upload"
          style="
            display: inline-block;
            width: 70%;
            border-radius: 25px;
            padding: 10px;
            border: 1px solid #3dc9b3;
          "
        >
          Upload Profile Photo
          <input
            type="file"
            accept="image/*"
            @change="
              uploadImage($event, 'profileImageUpload', 'profile-image-upload')
            "
            id="file-input"
            hidden
          />
        </label>
      </div>
    </div>
    <br />

    <!--<v-select
      v-model="userData.address"
      class="style-chooser"
      :class="{'six-pixel': errors.has('selectAddress')}" 
      @search="searchSuburb"
      :options="items"
      :reduce="displayAddress => displayAddress.displayAddress"
      label="displayAddress"
      ref="autocomplete"
      :danger="errors.first('selectAddress')"
      :success="!errors.first('selectAddress') && userData.address!=''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      name="selectAddress"
      data-vv-as="Street Address"
      placeholder="Doctor Street Address">
    </v-select>-->
    <vs-select
      :danger="errors.first('address') ? true : false"
      :success="!errors.first('address') && userData.address !== ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-as="address"
      name="address"
      placeholder="Doctor Street Address"
      autocomplete
      v-model="userData.address"
      class="w-full"
      id="addressSuggestion"
      @input-change="autoCompleteFunc($event.target.value)"
    >
      <vs-select-item
        :key="index"
        :value="item.value"
        :text="item.text"
        v-for="(item, index) in addressItems"
      />
    </vs-select>
    <span class="text-danger text-sm">{{ errors.first("address") }}</span>

    <vs-input
      :danger="errors.first('abn')"
      :success="!errors.first('abn') && userData.abn != ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="ABN"
      name="abn"
      label-placeholder="ABN"
      placeholder="ABN"
      v-model="userData.abn"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("abn") }}</span>

    <div
      class="w-full vs-con-input style-changer tmargin"
      :class="{
        'mt-6': !userData.ahpraExiryDate,
        dateClass: userData.indemnityInsuranceProvider,
      }"
    >
      <label class="vs-input--label custom-label" v-if="userData.dateOfBirth"
        >Date Of Birth</label
      >
      <datepicker
        :disabled-dates="disabledFutureDates"
        format="dd/MM/yyyy"
        v-model="userData.dateOfBirth"
        :input-class="{
          'is-true': !errors.has('dateOfBirth') && userData.dateOfBirth,
          'is-danger': errors.has('dateOfBirth'),
        }"
        :class="{ 'six-pixel': errors.has('dateOfBirth') }"
        placeholder="Date Of Birth"
      >
        <template slot="afterDateInput">
          <span
            v-if="userData.dateOfBirth"
            class="bg-color-done input-icon-validate vs-input--icon-validate"
          >
            <i
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >done</i
            >
          </span>
          <span
            v-if="errors.has('dateOfBirth')"
            class="bg-color-error input-icon-validate vs-input--icon-validate"
          >
            <i
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >error</i
            >
          </span>
        </template>
      </datepicker>
    </div>
    <span class="text-danger text-sm">{{ errors.first("dateOfBirth") }}</span>
    <input
      type="hidden"
      data-vv-as="Date Of Birth"
      data-vv-validate-on="change"
      name="dateOfBirth"
      v-validate="'required'"
      v-model="userData.dateOfBirth"
    />

    <vs-input
      :danger="errors.first('preferredName')"
      :success="!errors.first('preferredName') && userData.preferredName != ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate
      data-vv-validate-on="blur"
      data-vv-as="preferred name"
      name="preferredName"
      label-placeholder="preferred Name"
      placeholder="Preferred Name(Optional)"
      v-model="userData.preferredName"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("preferredName") }}</span>

    <vs-input
      :danger="errors.first('contactNumber')"
      :success="!errors.first('contactNumber') && userData.contactNumber != ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="contact number"
      name="contactNumber"
      label-placeholder="Contact Number"
      placeholder="Contact Number"
      v-model="userData.contactNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("contactNumber") }}</span>

    <vs-input
      :danger="errors.first('prescriberNumber')"
      :success="
        !errors.first('prescriberNumber') && userData.prescriberNumber != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required|digits:7'"
      data-vv-validate-on="blur"
      data-vv-as="Prescriber Number "
      name="prescriberNumber"
      label-placeholder="Prescriber Number"
      placeholder="Prescriber Number"
      v-model="userData.prescriberNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("prescriberNumber") }}
    </span>

    <vs-input
      :danger="errors.first('ahpraRegistrationNumber')"
      :success="
        !errors.first('ahpraRegistrationNumber') &&
        userData.ahpraRegistrationNumber != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="AHPRA Registration Number"
      name="ahpraRegistrationNumber"
      label-placeholder="AHPRA Registration Number"
      placeholder="AHPRA Registration Number"
      v-model="userData.ahpraRegistrationNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("ahpraRegistrationNumber") }}
    </span>

    <!-- <vs-input
      :danger="errors.first('ahpraExiryDate')"
      :success="!errors.first('ahpraExiryDate') && userData.ahpraExiryDate!=''"
      val-icon-success="done"
      val-icon-danger="error"
      type="text"
      onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="APHRA expiry date"
      name="ahpraExiryDate"
      label-placeholder="APHRA expiry date"
      placeholder="APHRA expiry date"
      v-model="userData.ahpraExiryDate"
      class="w-full mt-6" />
    <span class="text-danger text-sm">{{ errors.first('ahpraExiryDate') }}</span>-->

    <!-- datepicker -->
    <div
      class="w-full vs-con-input style-changer tmargin"
      :class="{
        'mt-6': !userData.ahpraExiryDate,
        dateClass: userData.indemnityInsuranceProvider,
      }"
    >
      <label class="vs-input--label custom-label" v-if="userData.ahpraExiryDate"
        >AHPRA Registration Expiry</label
      >
      <datepicker
        :disabled-dates="disabledDates"
        format="dd/MM/yyyy"
        v-model="userData.ahpraExiryDate"
        :input-class="{
          'is-true': !errors.has('ahpraExiryDate') && userData.ahpraExiryDate,
          'is-danger': errors.has('ahpraExiryDate'),
        }"
        :class="{ 'six-pixel': errors.has('ahpraExiryDate') }"
        placeholder="AHPRA Registration Expiry"
      >
        <template slot="afterDateInput">
          <span
            v-if="userData.ahpraExiryDate"
            class="bg-color-done input-icon-validate vs-input--icon-validate"
          >
            <i
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >done</i
            >
          </span>
          <span
            v-if="errors.has('ahpraExiryDate')"
            class="bg-color-error input-icon-validate vs-input--icon-validate"
          >
            <i
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >error</i
            >
          </span>
        </template>
      </datepicker>
    </div>
    <span class="text-danger text-sm">
      {{ errors.first("ahpraExiryDate") }}
    </span>
    <input
      type="hidden"
      data-vv-as="AHPRA expiry date"
      data-vv-validate-on="change"
      name="ahpraExiryDate"
      v-validate="'required'"
      v-model="userData.ahpraExiryDate"
    />
    <!-- end of datepicker  -->
    <vs-input
      :danger="errors.first('indemnityInsuranceProvider')"
      :success="
        !errors.first('indemnityInsuranceProvider') &&
        userData.indemnityInsuranceProvider != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="indemnity insurance provider"
      label-placeholder="Indemnity Insurance Provider"
      name="indemnityInsuranceProvider"
      placeholder="Indemnity Insurance Provider"
      v-model="userData.indemnityInsuranceProvider"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("indemnityInsuranceProvider") }}
    </span>

    <vs-input
      :danger="errors.first('indemnityInsuranceNumber')"
      :success="
        !errors.first('indemnityInsuranceNumber') &&
        userData.indemnityInsuranceNumber != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="indemnity insurance number"
      label-placeholder="Indemnity Insurance Number"
      name="indemnityInsuranceNumber"
      placeholder="Indemnity Insurance Number"
      v-model="userData.indemnityInsuranceNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("indemnityInsuranceNumber") }}
    </span>

    <div
      class="w-full vs-con-input tmargin"
      :class="{ 'mt-6': !userData.indemnityInsuranceExpiry }"
    >
      <label
        class="vs-input--label custom-label"
        v-if="userData.indemnityInsuranceExpiry"
        >Indemnity Insurance Expiry</label
      >
      <datepicker
        format="dd/MM/yyyy"
        :disabled-dates="disabledDates"
        v-model="userData.indemnityInsuranceExpiry"
        :input-class="{
          'is-true':
            !errors.has('indemnityInsuranceExpiry') &&
            userData.indemnityInsuranceExpiry,
          'is-danger': errors.has('indemnityInsuranceExpiry'),
        }"
        :class="{ 'six-pixel': errors.has('indemnityInsuranceExpiry') }"
        placeholder="Indemnity Insurance Expiry"
      >
        <template slot="afterDateInput">
          <span
            v-if="userData.indemnityInsuranceExpiry"
            class="bg-color-done input-icon-validate vs-input--icon-validate"
          >
            <i
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >done</i
            >
          </span>
          <span
            v-if="errors.has('indemnityInsuranceExpiry')"
            class="bg-color-error input-icon-validate vs-input--icon-validate"
          >
            <i
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >error</i
            >
          </span>
        </template>
      </datepicker>
    </div>
    <span class="text-danger text-sm">
      {{ errors.first("indemnityInsuranceExpiry") }}
    </span>
    <input
      type="hidden"
      data-vv-as="APHRA expiry date"
      data-vv-validate-on="change"
      name="indemnityInsuranceExpiry"
      v-validate="'required'"
      v-model="userData.indemnityInsuranceExpiry"
    />
    <!-- end of datepicker  -->
    <vs-divider />

    <div style="display: flex">
      <h6>Identity Document</h6>
      <vx-tooltip
        text="Please upload Driving licence or Passport or Identity card. Scans of both the front and back are usually required for government-issued IDs and driver's licenses. A passport scan is always acceptable and preferred. Files need to be JPEGs or PNGs smaller than 10MB. We cannot verify PDFs. Files should be rotated with the image right-side up, and have all information clearly legible."
      >
        <feather-icon
          style="cursor: pointer"
          icon="InfoIcon"
          svgClasses="h-4 w-4"
          class="ml-1"
        />
      </vx-tooltip>
    </div>

    <div :class="{ dateClass: !errors.has('identityDocumentFront') }">
      <label class="vs-input--label custom-label">Front</label>
      <div style="margin-top: 3px">
        <div class="mb-4" v-if="userData.identityDocument.front">
          <div
            v-if="determineFileType(userData.identityDocument.front) === 'pdf'"
            style="margin: auto; width: 50%"
          >
            <a :href="userData.identityDocument.front" target="_blank"
              >Click here to view file</a
            >
          </div>
          <div v-else style="margin: auto" class="register-img-wrapper">
            <img :src="userData.identityDocument.front" class="img-fluid" />
          </div>
        </div>
        <div
          id="identityDocumentFrontUpload"
          :class="{ 'mt-3': userData.identityDocument.front }"
          style="text-align: center; position: relative"
          class="vs-con-loading__container"
        >
          <label
            id="identityDocumentFrontId"
            :class="{ 'six-pixel': errors.has('identityDocumentFront') }"
            class="image-upload indentity-upload-front-document"
            style="
              border: 1px solid #cccccc;
              display: inline-block;
              width: 100%;
              border-radius: 5px;
              padding: 10px;
            "
          >
            + Attach Document Front
            <input
              type="file"
              accept="image/*"
              @change="
                uploadImage(
                  $event,
                  'identityDocumentFrontUpload',
                  'indentity-upload-front-document'
                )
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </div>
    </div>
    <span class="text-danger text-sm">
      {{ errors.first("identityDocumentFront") }}
    </span>

    <div :class="{ dateClass: !errors.has('identityDocumentBack') }">
      <label class="vs-input--label custom-label">Back</label>
      <div style="margin-top: 3px">
        <div class="mb-4" v-if="userData.identityDocument.back">
          <div
            v-if="determineFileType(userData.identityDocument.back) === 'pdf'"
            style="margin: auto; width: 50%"
          >
            <a :href="userData.identityDocument.back" target="_blank"
              >Click here to view file</a
            >
          </div>
          <div v-else style="margin: auto" class="register-img-wrapper">
            <img :src="userData.identityDocument.back" class="img-fluid" />
          </div>
          <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.identityDocument.back" width="100%" height="100%" />
          </div>-->
        </div>
        <div
          id="identityDocumentBackUpload"
          :class="{ 'mt-3': userData.identityDocument.back }"
          style="text-align: center; position: relative"
          class="vs-con-loading__container"
        >
          <label
            id="identityDocumentBackId"
            :class="{ 'six-pixel': errors.has('identityDocumentBack') }"
            class="image-upload indentity-upload-back-document"
            style="
              border: 1px solid #cccccc;
              display: inline-block;
              width: 100%;
              border-radius: 5px;
              padding: 10px;
            "
          >
            + Attach Document Back
            <input
              type="file"
              accept="image/*"
              @change="
                uploadImage(
                  $event,
                  'identityDocumentBackUpload',
                  'indentity-upload-back-document'
                )
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </div>
    </div>
    <span class="text-danger text-sm">
      {{ errors.first("identityDocumentBack") }}
    </span>

    <vs-divider />

    <div :class="{ dateClass: !errors.has('aphraCertificate') }">
      <label class="vs-input--label custom-label"
        >Attach AHPRA Certificate</label
      >
      <div style="margin-top: 3px">
        <div class="mb-4" v-if="userData.ahpraCertificate">
          <div
            v-if="determineFileType(userData.ahpraCertificate) === 'pdf'"
            style="margin: auto; width: 50%"
          >
            <a :href="userData.ahpraCertificate" target="_blank"
              >Click here to view file</a
            >
          </div>
          <div v-else style="margin: auto" class="register-img-wrapper">
            <img :src="userData.ahpraCertificate" class="img-fluid" />
          </div>
        </div>
        <div
          :class="{ 'mt-3': userData.ahpraCertificate }"
          id="aphraUpload"
          style="text-align: center; position: relative"
          class="vs-con-loading__container"
        >
          <label
            id="ahpraId"
            :class="{ 'six-pixel': errors.has('aphraCertificate') }"
            class="image-upload aphra-certificate-upload"
            style="
              border: 1px solid #cccccc;
              display: inline-block;
              width: 100%;
              border-radius: 5px;
              padding: 10px;
            "
          >
            + Attach Document
            <input
              type="file"
              @change="
                uploadImage($event, 'aphraUpload', 'aphra-certificate-upload')
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </div>
    </div>
    <span class="text-danger text-sm">
      {{ errors.first("aphraCertificate") }}
    </span>

    <div :class="{ dateClass: !errors.has('insuranceCertificate') }">
      <label class="vs-input--label custom-label"
        >Attach Insurance Certificate</label
      >
      <div style="margin-top: 3px">
        <div class="mb-4" v-if="userData.insuranceCertificate">
          <div
            v-if="determineFileType(userData.insuranceCertificate) === 'pdf'"
            style="margin: auto; width: 50%"
          >
            <a :href="userData.insuranceCertificate" target="_blank"
              >Click here to view file</a
            >
          </div>
          <div v-else style="margin: auto" class="register-img-wrapper">
            <img :src="userData.insuranceCertificate" class="img-fluid" />
          </div>
          <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
        </div>
        <div
          id="insuranceUpload"
          :class="{ 'mt-3': userData.insuranceCertificate }"
          style="text-align: center; position: relative"
          class="vs-con-loading__container"
        >
          <label
            id="insuranceId"
            :class="{ 'six-pixel': errors.has('insuranceCertificate') }"
            class="image-upload insurance-certificate-upload"
            style="
              border: 1px solid #cccccc;
              display: inline-block;
              width: 100%;
              border-radius: 5px;
              padding: 10px;
            "
          >
            + Attach Document
            <input
              type="file"
              @change="
                uploadImage(
                  $event,
                  'insuranceUpload',
                  'insurance-certificate-upload'
                )
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </div>
    </div>
    <span class="text-danger text-sm">
      {{ errors.first("insuranceCertificate") }}
    </span>

    <!-- <div :class="{ dateClass: !errors.has('electronicSignature') }">
      <label class="vs-input--label custom-label"
        >Attach Electronic Signature</label
      >
      <div style="margin-top:3px;">
        <div class="mb-4" v-if="userData.signature">
          <div
            v-if="determineFileType(userData.signature) === 'pdf'"
            style="margin:auto;width:50%;"
          >
            <a :href="userData.signature" target="_blank">
              Click here to view file</a
            >
          </div>
          <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.signature" width="100%" height="100%" />
          </div>
        </div>
        <div
          id="signatureUpload"
          :class="{ 'mt-3': userData.signature }"
          style="text-align:center;position:relative;"
          class="vs-con-loading__container"
        >
          <label
            id="signatureId"
            :class="{ 'six-pixel': errors.has('electronicSignature') }"
            class="image-upload"
            style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
          >
            + Attach Signature
            <input
              type="file"
              @change="uploadImage($event, 'signatureUpload')"
              accept="image/*"
              id="file-input"
              hidden
            />
          </label>
        </div>
      </div>
    </div>
    <span class="text-danger text-sm">{{
      errors.first("electronicSignature")
    }}</span>-->
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import axios from "@/axios";
import vSelect from "vue-select";
import { mapActions } from "vuex";

export default {
  name: "DoctorRegister",
  props: ["userData"],
  components: {
    "v-select": vSelect,
    Datepicker,
  },
  data() {
    return {
      flashMessage: "",
      showDismissibleAlert: false,
      disabledDates: { to: new Date() },
      disabledFutureDates: { from: new Date() },
      page: 1,
      limit: 10,
      searchText: "",
      items: [],
      selected: "",
      addressItems: [],
    };
  },
  methods: {
    ...mapActions("doctor", ["fetchSuburbs"]),
    async getSuburb() {
      var self = this;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      if (this.searchText !== "") {
        data.searchText = this.searchText;
      }
      await this.fetchSuburbs(data).then((res) => {
        self.items = res.data.data.docs;
      });
    },
    async searchSuburb(searchText) {
      this.searchText = searchText;
      await this.getSuburb();
    },
    chang(key = "") {
      this.$vs.loading({
        text: "Uploading File...",
        color: "#3dc9b3",
        background: "white",
        container: "#" + key + " div div",
      });
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.userData.profileImage = res.data.Location;
          }

          if (key === "identityDocumentFrontUpload") {
            this.errors.remove("identityDocumentFront");
            document.getElementById(
              "identityDocumentFrontId"
            ).style.borderColor = "#cccccc";
            self.userData.identityDocument.front = res.data.Location;
          }

          if (key === "identityDocumentBackUpload") {
            this.errors.remove("identityDocumentBack");
            document.getElementById(
              "identityDocumentBackId"
            ).style.borderColor = "#cccccc";
            self.userData.identityDocument.back = res.data.Location;
          }

          if (key === "aphraUpload") {
            this.errors.remove("aphraCertificate");
            document.getElementById("ahpraId").style.borderColor = "#cccccc";
            self.userData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            this.errors.remove("insuranceCertificate");
            document.getElementById("insuranceId").style.borderColor =
              "#cccccc";
            self.userData.insuranceCertificate = res.data.Location;
          }
          // if (key === "signatureUpload") {
          //   this.errors.remove("electronicSignature");
          //   self.userData.signature = res.data.Location;
          //   document.getElementById("signatureId").style.borderColor =
          //     "#cccccc";
          // }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    autoCompleteFunc(text) {
      if (text.length > 0)
        axios
          .get("/api/v1/getSuburbs", { params: { searchText: text } })
          .then((response) => {
            if (response.data.data.docs.length > 0) {
              const arr = response.data.data.docs.map((singleItem) => {
                singleItem.text = singleItem.displayAddress;
                singleItem.value = singleItem.displayAddress;
                return singleItem;
              });
              this.addressItems = [];
              this.addressItems = arr;
            }
          });
    },
  },
  async mounted(key = "profileImageUpload") {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
    await this.getSuburb();
    axios.get("/api/v1/getSuburbs").then((response) => {
      if (response.data.data.docs.length > 0) {
        const arr = response.data.data.docs.map((singleItem) => {
          singleItem.value = singleItem.displayAddress;
          singleItem.text = singleItem.displayAddress;
          return singleItem;
        });
        this.addressItems = arr;
      }
    });
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.vdp-datepicker div input::placeholder {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  letter-spacing: 0.01rem;
}
.custom-label {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.5);
}
.dateClass {
  padding-bottom: 0.3rem !important;
}
.image-upload {
  cursor: pointer;
}
.tmargin {
  margin-top: -5px;
}
.six-pixel {
  margin-bottom: 6px;
}
.avatar {
  border-radius: 50%;
}
.style-chooser .vs__search::placeholder {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 0.85rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  letter-spacing: 0.01rem;
}
</style>
