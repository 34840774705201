<template>
  <div>
    <br />
    <vs-input
      :danger="errors.first('companyName')"
      :success="!errors.first('companyName') && userData.companyName !== ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="company name"
      name="companyName"
      label-placeholder="Company Name"
      placeholder="Company Name"
      v-model="userData.companyName"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("companyName") }}</span>

    <vs-input
      :danger="errors.first('franchiseBusiness')"
      :success="
        !errors.first('franchiseBusiness') &&
        userData.franchiseBusinessName != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate
      data-vv-validate-on="blur"
      data-vv-as="franchise business name"
      name="franchiseBusiness"
      label-placeholder="Franchise Business Name"
      placeholder="Franchise Business Name(Optional)"
      v-model="userData.franchiseBusinessName"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("franchiseBusiness") }}
    </span>

    <vs-input
      :danger="errors.first('abn')"
      :success="!errors.first('abn') && userData.abn != ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="company ABN"
      name="abn"
      label-placeholder="Company ABN"
      placeholder="Company ABN"
      v-model="userData.abn"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("abn") }}</span>

    <!-- <vs-input
      :danger="errors.first('displayAddress')"
      :success="!errors.first('displayAddress') && userData.address.displayAddress!=''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="head office street address"
      name="displayAddress"
      label-placeholder="Head Office Street Address"
      placeholder="Head Office Street Address"
      v-model="userData.address.displayAddress"
    class="w-full mt-6" />-->
    <div
      class="
        vs-component vs-con-input-label vs-input
        w-full
        mt-6
        vs-input-primary
        is-label-placeholder
      "
      :class="{
        'input-icon-validate-danger':
          errors.first('displayAddress') ||
          (userData.address.displayAddress &&
            !userData.address.coordinates[0]) ||
          checkDisplayAddress != userData.address.displayAddress,
        'input-icon-validate-success':
          userData.address.coordinates[0] &&
          checkDisplayAddress == userData.address.displayAddress,
      }"
    >
      <!---->
      <div class="vs-con-input">
        <input
          type="text"
          placeholder
          ref="autocomplete"
          v-validate="'required'"
          data-vv-validate-on="blur"
          data-vv-as="franchise street address"
          name="displayAddress"
          v-model="userData.address.displayAddress"
          class="vs-inputx vs-input--input normal"
          :class="{ hasValue: userData.address.displayAddress }"
          style="border: 1px solid rgba(0, 0, 0, 0.2)"
        />
        <span
          class="
            input-span-placeholder
            vs-input--placeholder
            normal normal
            vs-placeholder-label
          "
          >Franchise Street Address</span
        >
        <!---->
        <span class="input-icon-validate vs-input--icon-validate">
          <i
            v-if="
              userData.address.coordinates[0] &&
              checkDisplayAddress == userData.address.displayAddress
            "
            valiconpack="material-icons"
            class="vs-icon notranslate icon-scale material-icons null"
            >done</i
          >
          <i
            v-else-if="
              errors.first('displayAddress') ||
              (userData.address.displayAddress &&
                !userData.address.coordinates[0]) ||
              checkDisplayAddress != userData.address.displayAddress
            "
            valiconpack="material-icons"
            class="vs-icon notranslate icon-scale material-icons null"
            >error</i
          >
        </span>
      </div>
      <span>
        <div class="con-text-validation vs-input--text-validation">
          <span
            class="
              span-text-validation span-text-validation-success
              vs-input--text-validation-span
            "
          ></span>
        </div>
      </span>
    </div>
    <span class="text-danger text-sm">
      {{ errors.first("displayAddress") }}
    </span>

    <vs-input
      :danger="errors.first('contactNumber')"
      :success="!errors.first('contactNumber') && userData.contactNumber != ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="contact number"
      label-placeholder="Contact Number"
      name="contactNumber"
      placeholder="Contact Number"
      v-model="userData.contactNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("contactNumber") }}</span>

    <vs-input
      :danger="errors.first('headOfficePostalAddress')"
      :success="
        !errors.first('headOfficePostalAddress') && userData.postalAddress != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate
      data-vv-validate-on="blur"
      data-vv-as="head office postal address"
      name="headOfficePostalAddress"
      label-placeholder="Head Office Postal Address"
      placeholder="Head Office Postal Address(Optional)"
      v-model="userData.postalAddress"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("headOfficePostalAddress") }}
    </span>

    <vs-input
      :danger="errors.first('bestContactName')"
      :success="
        !errors.first('bestContactName') && userData.bestContactName != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="best contact name"
      label-placeholder="Best Contact Name"
      name="bestContactName"
      placeholder="Best Contact Name"
      v-model="userData.bestContactName"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("bestContactName") }}
    </span>

    <vs-input
      :danger="errors.first('bestContactNumber')"
      :success="
        !errors.first('bestContactNumber') && userData.bestContactNumber != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="best contact number"
      label-placeholder="Best Contact Number"
      name="bestContactNumber"
      placeholder="Best Contact Number"
      v-model="userData.bestContactNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{
      errors.first("bestContactNumber")
    }}</span>

    <vs-input
      :danger="errors.first('bestContactemail')"
      :success="
        !errors.first('bestContactemail') && userData.bestContactEmail != ''
      "
      val-icon-success="done"
      val-icon-danger="error"
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      data-vv-as="best contact email"
      name="bestContactemail"
      type="email"
      label-placeholder="Email"
      placeholder="Best Contact Email"
      v-model="userData.bestContactEmail"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">
      {{ errors.first("bestContactemail") }}
    </span>
    <vs-checkbox
      ref="centralise"
      v-model="userData.wantCentralisebilling"
      class="mt-6"
      >Do you require centralised billing ?</vs-checkbox
    >

    <vs-input
      :danger="errors.first('invoiceEmail')"
      :success="!errors.first('invoiceEmail') && userData.invoiceEmail != ''"
      val-icon-success="done"
      val-icon-danger="error"
      v-if="userData.wantCentralisebilling"
      v-validate="'required_if:centralise,true|email'"
      data-vv-validate-on="blur"
      data-vv-as="invoice email address"
      label-placeholder="Invoice Email Address"
      name="invoiceEmail"
      placeholder="Invoice Email Address"
      v-model="userData.invoiceEmail"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("invoiceEmail") }}</span>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
const dict = {
  custom: {
    contactNumber: {
      required: "The contact number field is required",
      regex: "Invalid Australian number",
    },
    bestContactNumber: {
      required: "The contact number field is required",
      regex: "Invalid Australian number",
    },
  },
};

// register custom messages
Validator.localize("en", dict);
export default {
  name: "FranchiseRegister",
  props: ["userData"],
  data() {
    return {
      checkDisplayAddress: "",
    };
  },
  methods: {
    place_changed(a) {},
  },
  mounted() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.$refs.autocomplete,
      { types: ["geocode"], componentRestrictions: { country: "au" } }
    );
    this.autocomplete.addListener("place_changed", () => {
      let place = this.autocomplete.getPlace();
      this.errors.remove("displayAddress");
      let ac = place.address_components;
      this.userData.address.addressData = place.address_components;
      this.userData.address.addressData[0].checkDisplayAddress =
        place.formatted_address;
      this.checkDisplayAddress = place.formatted_address;
      let lat = place.geometry.location.lat();
      let lon = place.geometry.location.lng();
      this.userData.address.displayAddress = place.formatted_address;
      this.userData.address.coordinates[0] = lon;
      this.userData.address.coordinates[1] = lat;
    });
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
