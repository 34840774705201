<template>
  <div>
    <br />
    <div>
      <vs-input
        :danger="errors.first('companyName') ? true : false"
        :success="!errors.first('companyName') && userData.companyName != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="company name"
        name="companyName"
        label-placeholder="Company Name"
        placeholder="Company Name"
        v-model="userData.companyName"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("companyName") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('clinicName') ? true : false"
        :success="!errors.first('clinicName') && userData.clinicName != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="clinic name"
        name="clinicName"
        label-placeholder="Clinic Name"
        placeholder="Clinic Name"
        v-model="userData.clinicName"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("clinicName") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('abn') ? true : false"
        :success="!errors.first('abn') && userData.abn != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="company ABN"
        name="abn"
        label-placeholder="Company ABN"
        placeholder="Company ABN"
        v-model="userData.abn"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("abn") }}</span>
    </div>

    <div>
      <div :class="{ dateClass: !errors.has('abnCertificate') }">
        <label class="vs-input--label custom-label"
          >ABN Certificate (Please upload your ABN certificate, or a screenshot
          from https://abr.business.gov.au/)</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.abnCertificate">
            <div
              v-if="determineFileType(userData.abnCertificate) === 'pdf'"
              style="margin: auto; width: 50%"
            >
              <a :href="userData.abnCertificate" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <a :href="userData.abnCertificate" target="_blank">
                <img :src="userData.abnCertificate" class="img-fluid" />
              </a>
            </div>
          </div>
          <div
            id="abnCertificateUpload"
            :class="{ 'mt-3': userData.abnCertificate }"
            style="text-align: center; position: relative"
          >
            <label
              id="abnCertificateId"
              :class="{ 'six-pixel': errors.has('abnCertificate') }"
              class="image-upload abn-certificate-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage(
                    $event,
                    'abnCertificateUpload',
                    'abn-certificate-upload'
                  )
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("abnCertificate") }}
      </span>
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.councilPermits"
        from="register"
        loadingClass="councilPermits"
        label="Please upload any required council permits (Optional)"
        popupTitle="Councli Permits"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.poisonsCertificate"
        from="register"
        loadingClass="poisonsCertificate"
        label="Please upload your poisons certificate (For clinics in VIC and WA)
        (Optional)"
        popupTitle="Poisons Certificate"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <div
        class="
          vs-component vs-con-input-label vs-input
          w-full
          mt-6
          vs-input-primary
          is-label-placeholder
        "
        :class="{
          'input-icon-validate-danger':
            errors.first('displayAddress') ||
            (userData.address.displayAddress &&
              !userData.address.coordinates[0]) ||
            checkDisplayAddress != userData.address.displayAddress,
          'input-icon-validate-success':
            userData.address.coordinates[0] &&
            checkDisplayAddress == userData.address.displayAddress,
        }"
      >
        <!---->
        <div class="vs-con-input">
          <input
            type="text"
            placeholder
            ref="autocomplete"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="clinic street address"
            name="displayAddress"
            v-model="userData.address.displayAddress"
            class="vs-inputx vs-input--input normal"
            :class="{ hasValue: userData.address.displayAddress }"
            style="border: 1px solid rgba(0, 0, 0, 0.2)"
          />
          <span
            class="
              input-span-placeholder
              vs-input--placeholder
              normal normal
              vs-placeholder-label
            "
            >Clinic Street Address</span
          >
          <!---->
          <span class="input-icon-validate vs-input--icon-validate">
            <i
              v-if="
                userData.address.coordinates[0] &&
                checkDisplayAddress == userData.address.displayAddress
              "
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >done</i
            >
            <i
              v-else-if="
                errors.first('displayAddress') ||
                (userData.address.displayAddress &&
                  !userData.address.coordinates[0]) ||
                checkDisplayAddress != userData.address.displayAddress
              "
              valiconpack="material-icons"
              class="vs-icon notranslate icon-scale material-icons null"
              >error</i
            >
          </span>
        </div>
        <span>
          <div class="con-text-validation vs-input--text-validation">
            <span
              class="
                span-text-validation span-text-validation-success
                vs-input--text-validation-span
              "
            ></span>
          </div>
        </span>
      </div>
      <span
        class="text-danger text-sm"
        v-if="
          errors.first('displayAddress') ||
          (userData.address.displayAddress &&
            !userData.address.coordinates[0]) ||
          checkDisplayAddress != userData.address.displayAddress
        "
        >Enter valid address.</span
      >
    </div>

    <div>
      <vs-input
        :danger="errors.first('clinicSuburb') ? true : false"
        :success="
          !errors.first('clinicSuburb') && userData.address.suburb != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="clinic suburb"
        name="clinicSuburb"
        label-placeholder="Clinic Suburb"
        placeholder="Clinic Suburb"
        v-model="userData.address.suburb"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("clinicSuburb")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('clinicPostCode') ? true : false"
        :success="
          !errors.first('clinicPostCode') && userData.address.postCode != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required|integer'"
        data-vv-validate-on="blur"
        data-vv-as="clinic post code"
        name="clinicPostCode"
        label-placeholder="Clinic Post Code"
        placeholder="Clinic Post Code"
        v-model="userData.address.postCode"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("clinicPostCode")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('clinicState') ? true : false"
        :success="!errors.first('clinicState') && userData.address.state != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="clinic state"
        name="clinicState"
        label-placeholder="Clinic State"
        placeholder="Clinic State"
        v-model="userData.address.state"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("clinicState") }}</span>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1">
          Is this a residential address?
        </label>
        <vs-radio
          vs-value="Yes"
          vs-name="radios1"
          style="margin-right: 20px"
          v-model="userData.isResidentialAddress"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios1"
          v-model="userData.isResidentialAddress"
          >No</vs-radio
        >
        <span
          v-if="validationKeys.showIsResidentialAddressError"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <vs-input
        :danger="errors.first('clinicAddress1') ? true : false"
        :success="
          !errors.first('clinicAddress1') && userData.clinicAddress1 != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="Clinic Address 1"
        name="clinicAddress1"
        label-placeholder="Clinic Address 1"
        placeholder="Clinic Address 1 (Optional)"
        v-model="userData.clinicAddress1"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("clinicAddress1")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('postalAddress') ? true : false"
        :success="
          !errors.first('postalAddress') && userData.postalAddress != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="clinic postal address"
        name="postalAddress"
        label-placeholder="Clinic Postal Address"
        placeholder="Clinic Postal Address(Optional)"
        v-model="userData.postalAddress"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("postalAddress")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('bestContactName') ? true : false"
        :success="
          !errors.first('bestContactName') && userData.bestContactName != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="best contact name"
        label-placeholder="Best Contact Name"
        name="bestContactName"
        placeholder="Best Contact Name"
        v-model="userData.bestContactName"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">
        {{ errors.first("bestContactName") }}
      </span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('bestContactNumber') ? true : false"
        :success="
          !errors.first('bestContactNumber') && userData.bestContactNumber != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="best contact number"
        label-placeholder="Best Contact Number"
        name="bestContactNumber"
        placeholder="Best Contact Number"
        v-model="userData.bestContactNumber"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("bestContactNumber")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('bestContactEmail') ? true : false"
        :success="
          !errors.first('bestContactEmail') && userData.bestContactEmail != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="{
          required: true,
          email: true,
        }"
        data-vv-validate-on="blur"
        data-vv-as="best contact email"
        label-placeholder="Best Contact Email"
        name="bestContactEmail"
        placeholder="Best Contact Email"
        v-model="userData.bestContactEmail"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("bestContactEmail")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('instagram') ? true : false"
        :success="!errors.first('instagram') && userData.instagram != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="instagram"
        name="instagram"
        label-placeholder="instagram"
        placeholder="Instagram (Optional)"
        v-model="userData.instagram"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("instagram") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('facebook') ? true : false"
        :success="!errors.first('facebook') && userData.facebook != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="facebook"
        name="facebook"
        label-placeholder="facebook"
        placeholder="Facebook (Optional)"
        v-model="userData.facebook"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("facebook") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('website') ? true : false"
        :success="!errors.first('website') && userData.website != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="website"
        name="website"
        label-placeholder="website"
        placeholder="Website (Optional)"
        v-model="userData.website"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("website") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('contactNumber') ? true : false"
        :success="
          !errors.first('contactNumber') && userData.contactNumber != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        label-placeholder="Phone Number"
        name="phoneNumber"
        placeholder="Phone Number (Optional)"
        v-model="userData.contactNumber"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("contactNumber")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('invoiceEmail') ? true : false"
        :success="!errors.first('invoiceEmail') && userData.invoiceEmail != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required|email'"
        data-vv-validate-on="blur"
        data-vv-as="invoice email address"
        label-placeholder="Invoice Email Address"
        name="invoiceEmail"
        placeholder="Invoice Email Address"
        v-model="userData.invoiceEmail"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("invoiceEmail")
      }}</span>
    </div>

    <div>
      <clinic-opening-hours
        :weekDaysData="userData.clinicOpeningHours"
        from="register"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicFrontPhoto"
        from="register"
        loadingClass="clinicFrontPhoto"
        label="Upload Clinic Front Photos (Please upload a photo of the front of your clinic (from the street))"
        popupTitle="Clinic Front Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicFloorPhoto"
        from="register"
        loadingClass="clinicFloorPhoto"
        label="Upload Clinic Floor Photos (Please upload photos of the floor of the treatment areas of your clinic)"
        popupTitle="Clinic Floor Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicTreatmentBedPhoto"
        from="register"
        loadingClass="clinicTreatmentBedPhoto"
        label="Treatment bed (Please upload photos of the treatment beds in your clinic)"
        popupTitle="Clinic Treatment Bed Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicDrawingUpAreaPhoto"
        from="register"
        loadingClass="clinicDrawingUpAreaPhoto"
        label="Drawing up area (Please upload photos of the drawing up areas in your treatment rooms. Please show gloves and hand-washing facilities, if available)"
        popupTitle="Clinic Drawing Up Area Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicFridgePhoto"
        from="register"
        loadingClass="clinicFridgePhoto"
        label="Fridge (Please upload photos of the fridge for medication storage, including your temperature monitoring device. Show your lock if available)"
        popupTitle="Clinic Fridge Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicDrugStoragePhoto"
        from="register"
        loadingClass="clinicDrugStoragePhoto"
        label="Drug Storage (Please upload photos of the cupboard where other medications will be stored. Show the ability to lock the cupboard, if possible.)"
        popupTitle="Clinic Drug Storage Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicBinsPhoto"
        from="register"
        loadingClass="clinicBinsPhoto"
        label="Bins (Please show photos of your sharps bins and general storage bins.)"
        popupTitle="Clinic Bins Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicRoomLockPhoto"
        from="register"
        loadingClass="clinicRoomLockPhoto"
        label="Room Lock (Please show photos of the locks on your treatment room, if available.) (Optional)"
        popupTitle="Clinic Room Lock Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1">
          Do you have adrenaline and hyalase in the clinic?
        </label>
        <vs-radio
          vs-value="Yes"
          vs-name="radios99"
          style="margin-right: 20px"
          v-model="userData.haveAdrenalineAndHyalaseInClinic"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios99"
          v-model="userData.haveAdrenalineAndHyalaseInClinic"
          >No</vs-radio
        >
        <span
          v-if="validationKeys.showHaveAdrenalineAndHyalaseInClinicError"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <ClinicPhotos
        :clinicPhotosField="userData.clinicEpipenAndHyalasePhoto"
        from="register"
        loadingClass="clinicEpipenAndHyalasePhoto"
        label="Emergency Medication (Please upload photos of your epipen (or adrenaline) and hyalase. Please show expiry dates in the images. If you do not have these medications you may purchase them after signing up.) (Optional)"
        popupTitle="Clinic Emergency Medication Photos"
        @removeImage="removeImage"
      />
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1">
          Do you intend to secure S4 medication for your clinic through Fresh
          Clinics
        </label>
        <vs-radio
          vs-value="Yes"
          vs-name="radios999"
          style="margin-right: 20px"
          v-model="userData.intendToSecureS4Medication"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios999"
          style="margin-right: 20px"
          v-model="userData.intendToSecureS4Medication"
          >No</vs-radio
        >
        <vs-radio
          vs-value="Unsure"
          vs-name="radios999"
          v-model="userData.intendToSecureS4Medication"
          >Unsure</vs-radio
        >
        <span
          v-if="validationKeys.showIntendToSecureS4MedicationError"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div style="display: flex; margin-top: 20px">
      <div style="display: flex; width: 31px">
        <vs-checkbox
          style="margin-top: 0 !important"
          id="nurseInjector"
          v-model="userData.isNurseInjecter"
          class="mt-6 mr-2"
        ></vs-checkbox>
      </div>
      <div style="display: flex">
        <label for="nurseInjector" style="cursor: pointer"
          >Are you also the registered nurse injector at this clinic ?</label
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import ClinicOpeningHours from "../components/clinics/ClinicOpeningHours.vue";
import ClinicPhotos from "../components/clinics/ClinicPhotos";
import { mapActions } from "vuex";

export default {
  components: { ClinicOpeningHours, ClinicPhotos },
  name: "ClinicRegister",
  props: ["userData", "validationKeys"],
  data() {
    return {
      checkDisplayAddress: "",
    };
  },
  methods: {
    ...mapActions("clinic", ["uploadClinicImages", "removeClinicImages"]),
    place_changed(a) {},
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    removeImage(imageUrl, selector, loadingClass) {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `#${selector}`,
        scale: 0.45,
        text: "removing",
      });

      let self = this;

      self
        .removeClinicImages(imageUrl)
        .then((response) => {
          self.userData[loadingClass] = self.userData[loadingClass].filter(
            (value) => value.photoUrl != imageUrl
          );
          self.$vs.loading.close(`#${selector} > .con-vs-loading`);
        })
        .catch((error) => {
          self.$vs.notify({
            title: "Removing Failed",
            text: "Please try again later.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
          self.$vs.loading.close(`#${selector} > .con-vs-loading`);
        });
    },
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "abnCertificateUpload") {
            document.getElementById("abnCertificateId").style.borderColor =
              "#cccccc";
            self.userData.abnCertificate = res.data.Location;
            this.errors.remove("abnCertificate");
          }

          if (key === "councilPermitsUpload") {
            document.getElementById("councilPermitsId").style.borderColor =
              "#cccccc";
            self.userData.councilPermits = res.data.Location;
            this.errors.remove("councilPermits");
          }

          if (key === "poisonsCertificateUpload") {
            document.getElementById("poisonsCertificateId").style.borderColor =
              "#cccccc";
            self.userData.poisonsCertificate = res.data.Location;
            this.errors.remove("poisonsCertificate");
          }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    initializeGoogleAddress() {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        { types: ["geocode"], componentRestrictions: { country: "au" } }
      );
      this.autocomplete.addListener("place_changed", () => {
        let place = this.autocomplete.getPlace();
        this.errors.remove("displayAddress");
        let ac = place.address_components;
        this.userData.address.addressData = place.address_components;
        this.userData.address.addressData[0].checkDisplayAddress =
          place.formatted_address;
        this.checkDisplayAddress = place.formatted_address;
        let lat = place.geometry.location.lat();
        let lon = place.geometry.location.lng();
        this.userData.address.displayAddress = place.formatted_address;
        this.userData.address.coordinates[0] = lon;
        this.userData.address.coordinates[1] = lat;

        let self = this;

        self.userData.address.suburb = "";
        self.userData.address.state = "";
        self.userData.address.postCode = "";
        if (
          place &&
          place.address_components &&
          place.address_components.length > 0
        ) {
          place.address_components.forEach(function (gAdd) {
            if (gAdd.types[0] === "administrative_area_level_2") {
              self.userData.address.suburb = gAdd.long_name;
              self.errors.remove("clinicSuburb");
            }
            if (gAdd.types[0] === "administrative_area_level_1") {
              self.userData.address.state = gAdd.long_name;
              self.errors.remove("clinicState");
            }
            if (gAdd.types[0] === "postal_code") {
              self.userData.address.postCode = gAdd.long_name;
              self.errors.remove("clinicPostCode");
            }
          });
        }
      });
    },
  },
  mounted() {
    this.initializeGoogleAddress();
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
</style>
