<template>
  <div>
    <br />
    <div>
      <div>
        <div
          class="mb-4 img-wrapper"
          v-if="userData.profileImage"
          style="margin: auto"
        >
          <!-- <vs-avatar :src="userData.profileImage" size="145px" /> -->
          <img :src="userData.profileImage" class="img-fluid" alt />
        </div>
        <!-- <div class="mb-4" v-if="userData.profileImage" style="margin:auto;width:50%;">
        <vs-avatar :src="userData.profileImage" size="145px" />
      </div>-->
        <div class="mb-4" v-else style="margin: auto; width: 50%">
          <vs-avatar
            src="https://freshclinics-staging.s3.ap-southeast-2.amazonaws.com/undefined/tjl5fnug0jhy1588583500891.png"
            size="110px"
          />
        </div>
        <div
          id="profileImageUpload"
          style="text-align: center; position: relative"
        >
          <label
            class="image-upload profile-image-upload"
            style="
              display: inline-block;
              width: 70%;
              border-radius: 25px;
              padding: 10px;
              border: 1px solid #3dc9b3;
            "
          >
            Upload Profile Photo
            <input
              type="file"
              accept="image/*"
              @change="
                uploadImage(
                  $event,
                  'profileImageUpload',
                  'profile-image-upload'
                )
              "
              id="file-input"
              hidden
            />
          </label>
        </div>
      </div>
    </div>

    <div>
      <vs-input
        :danger="errors.first('preferredName') ? true : false"
        :success="
          !errors.first('preferredName') && userData.preferredName != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="preferred name"
        name="preferredName"
        label-placeholder="preferred Name"
        placeholder="Preferred Name(Optional)"
        v-model="userData.preferredName"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("preferredName")
      }}</span>
    </div>

    <div>
      <div
        class="w-full vs-con-input style-changer"
        :class="{
          'mt-6': !userData.dateOfBirth,
          dateClass: userData.dateOfBirth,
        }"
      >
        <label class="vs-input--label custom-label" v-if="userData.dateOfBirth"
          >Date Of Birth</label
        >
        <datepicker
          format="dd/MM/yyyy"
          v-model="userData.dateOfBirth"
          :input-class="{
            'is-true': !errors.has('dateOfBirth') && userData.dateOfBirth,
            'is-danger': errors.has('dateOfBirth'),
          }"
          :class="{ 'six-pixel': errors.has('dateOfBirth') }"
          placeholder="Date Of Birth"
        >
          <template slot="afterDateInput">
            <span
              v-if="userData.dateOfBirth"
              class="bg-color-done input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >done</i
              >
            </span>
            <span
              v-if="errors.has('dateOfBirth')"
              class="bg-color-error input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >error</i
              >
            </span>
          </template>
        </datepicker>
      </div>
      <span class="text-danger text-sm">{{ errors.first("dateOfBirth") }}</span>
      <input
        type="hidden"
        data-vv-as="Date Of Birth"
        data-vv-validate-on="change"
        name="dateOfBirth"
        v-validate="'required'"
        v-model="userData.dateOfBirth"
      />
    </div>

    <div>
      <vs-input
        :danger="errors.first('contactNumber') ? true : false"
        :success="
          !errors.first('contactNumber') && userData.contactNumber != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="contact number"
        name="contactNumber"
        label-placeholder="Contact Number"
        placeholder="Contact Number"
        v-model="userData.contactNumber"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("contactNumber")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('ahpraRegistrationNumber') ? true : false"
        :success="
          !errors.first('ahpraRegistrationNumber') &&
          userData.ahpraRegistrationNumber != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="AHPRA Registration Number"
        name="ahpraRegistrationNumber"
        label-placeholder="AHPRA Registration Number"
        placeholder="AHPRA Registration Number"
        v-model="userData.ahpraRegistrationNumber"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">
        {{ errors.first("ahpraRegistrationNumber") }}
      </span>
    </div>

    <!-- <vs-input
      :danger="errors.first('ahpraExiryDate')"
      :success="!errors.first('ahpraExiryDate') && userData.ahpraExiryDate!=''"
      val-icon-success="done"
      val-icon-danger="error"
      type="text"
      onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="APHRA expiry date"
      name="ahpraExiryDate"
      label-placeholder="APHRA expiry date"
      placeholder="APHRA expiry date"
      v-model="userData.ahpraExiryDate"
      class="w-full mt-6" />
    <span class="text-danger text-sm">{{ errors.first('ahpraExiryDate') }}</span>-->

    <div>
      <div
        class="w-full vs-con-input tmargin"
        :class="{
          'mt-6': !userData.ahpraExiryDate,
          dateClass: userData.ahpraExiryDate,
        }"
      >
        <label
          class="vs-input--label custom-label"
          v-if="userData.ahpraExiryDate"
          >AHPRA Registration Expiry</label
        >
        <datepicker
          :disabled-dates="disabledDates"
          format="dd/MM/yyyy"
          v-model="userData.ahpraExiryDate"
          :input-class="{
            'is-true': !errors.has('ahpraExiryDate') && userData.ahpraExiryDate,
            'is-danger': errors.has('ahpraExiryDate'),
          }"
          :class="{ 'six-pixel': errors.has('ahpraExiryDate') }"
          placeholder="AHPRA Registration Expiry"
        >
          <template slot="afterDateInput">
            <span
              v-if="userData.ahpraExiryDate"
              class="bg-color-done input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >done</i
              >
            </span>
            <span
              v-if="errors.has('ahpraExiryDate')"
              class="bg-color-error input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >error</i
              >
            </span>
          </template>
        </datepicker>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("ahpraExiryDate") }}
      </span>
      <input
        type="hidden"
        data-vv-as="AHPRA expiry date"
        data-vv-validate-on="change"
        name="ahpraExiryDate"
        v-validate="'required'"
        v-model="userData.ahpraExiryDate"
      />
    </div>
    <!-- end of datepicker  -->

    <div>
      <vs-input
        :danger="errors.first('indemnityInsuranceProvider') ? true : false"
        :success="
          !errors.first('indemnityInsuranceProvider') &&
          userData.indemnityInsuranceProvider != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="indemnity insurance provider"
        label-placeholder="Indemnity Insurance Provider"
        name="indemnityInsuranceProvider"
        placeholder="Indemnity Insurance Provider"
        v-model="userData.indemnityInsuranceProvider"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">
        {{ errors.first("indemnityInsuranceProvider") }}
      </span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('indemnityInsuranceNumber') ? true : false"
        :success="
          !errors.first('indemnityInsuranceNumber') &&
          userData.indemnityInsuranceNumber != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="indemnity insurance number"
        label-placeholder="Indemnity Insurance Number"
        name="indemnityInsuranceNumber"
        placeholder="Indemnity Insurance Number"
        v-model="userData.indemnityInsuranceNumber"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">
        {{ errors.first("indemnityInsuranceNumber") }}
      </span>
    </div>

    <!-- <vs-input
      :danger="errors.first('indemnityInsuranceExpiry')"
      :success="!errors.first('indemnityInsuranceExpiry') && userData.indemnityInsuranceExpiry!=''"
      val-icon-success="done"
      val-icon-danger="error"
      type="text"
      onfocus="(this.type='date')" onblur="if(!this.value)this.type='text'"
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="indemnity insurance expiry"
      name="indemnityInsuranceExpiry"
      label-placeholder="Indemnity Insurance Expiry"
      placeholder="Indemnity Insurance Expiry"
      v-model="userData.indemnityInsuranceExpiry"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first('indemnityInsuranceExpiry') }}</span>-->

    <!-- datepicker -->

    <div>
      <div
        class="w-full vs-con-input style-changer tmargin"
        :class="{ 'mt-6': !userData.indemnityInsuranceExpiry }"
      >
        <label
          class="vs-input--label custom-label"
          v-if="userData.indemnityInsuranceExpiry"
          >Indemnity Insurance Expiry</label
        >
        <datepicker
          format="dd/MM/yyyy"
          :disabled-dates="disabledDates"
          v-model="userData.indemnityInsuranceExpiry"
          :input-class="{
            'is-true':
              !errors.has('indemnityInsuranceExpiry') &&
              userData.indemnityInsuranceExpiry,
            'is-danger': errors.has('indemnityInsuranceExpiry'),
          }"
          :class="{ 'six-pixel': errors.has('indemnityInsuranceExpiry') }"
          placeholder="Indemnity Insurance Expiry"
        >
          <template slot="afterDateInput">
            <span
              v-if="userData.indemnityInsuranceExpiry"
              class="bg-color-done input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >done</i
              >
            </span>
            <span
              v-if="errors.has('indemnityInsuranceExpiry')"
              class="bg-color-error input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >error</i
              >
            </span>
          </template>
        </datepicker>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("indemnityInsuranceExpiry") }}
      </span>
      <input
        type="hidden"
        data-vv-as="APHRA expiry date"
        data-vv-validate-on="change"
        name="indemnityInsuranceExpiry"
        v-validate="'required'"
        v-model="userData.indemnityInsuranceExpiry"
      />
    </div>
    <!-- end of datepicker  -->
    <vs-divider />

    <div>
      <div :class="{ dateClass: !errors.has('aphraCertificate') }">
        <label class="vs-input--label custom-label"
          >Attach AHPRA Certificate</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.ahpraCertificate">
            <div
              v-if="determineFileType(userData.ahpraCertificate) === 'pdf'"
              style="margin: auto; width: 50%"
            >
              <a :href="userData.ahpraCertificate" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img :src="userData.ahpraCertificate" class="img-fluid" />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.ahpraCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            :class="{ 'mt-3': userData.ahpraCertificate }"
            id="aphraUpload"
            style="text-align: center; position: relative"
          >
            <label
              id="ahpraId"
              :class="{ 'six-pixel': errors.has('aphraCertificate') }"
              class="image-upload ahpra-certificate-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage($event, 'aphraUpload', 'ahpra-certificate-upload')
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("aphraCertificate") }}
      </span>
    </div>

    <div>
      <div :class="{ dateClass: !errors.has('insuranceCertificate') }">
        <label class="vs-input--label custom-label"
          >Attach Insurance Certificate</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.insuranceCertificate">
            <div
              v-if="determineFileType(userData.insuranceCertificate) === 'pdf'"
              style="margin: auto; width: 50%"
            >
              <a :href="userData.insuranceCertificate" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img :src="userData.insuranceCertificate" class="img-fluid" />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            id="insuranceUpload"
            :class="{ 'mt-3': userData.insuranceCertificate }"
            style="text-align: center; position: relative"
          >
            <label
              id="insuranceId"
              :class="{ 'six-pixel': errors.has('insuranceCertificate') }"
              class="image-upload insurance-certificate-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage(
                    $event,
                    'insuranceUpload',
                    'insurance-certificate-upload'
                  )
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("insuranceCertificate") }}
      </span>
    </div>

    <br />

    <div>
      <label>
        Brief outline of your cosmetic experience - You can update this later.
      </label>
      <vs-textarea
        v-model="userData.nurseCosmeticExperience"
        v-validate="'max:255'"
        name="nurseCosmeticExperience"
        data-vv-validate-on="blur"
        data-vv-as="nurse cosmetic experience"
      />
      <span class="text-danger text-sm">
        {{ errors.first("nurseCosmeticExperience") }}
      </span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('instagram') ? true : false"
        :success="!errors.first('instagram') && userData.instagram != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="instagram"
        name="instagram"
        label-placeholder="instagram"
        placeholder="Instagram (Optional)"
        v-model="userData.instagram"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("instagram") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('facebook') ? true : false"
        :success="!errors.first('facebook') && userData.facebook != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="facebook"
        name="facebook"
        label-placeholder="facebook"
        placeholder="Facebook (Optional)"
        v-model="userData.facebook"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("facebook") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('website') ? true : false"
        :success="!errors.first('website') && userData.website != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="website"
        name="website"
        label-placeholder="website"
        placeholder="Website (Optional)"
        v-model="userData.website"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{ errors.first("website") }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('clinicName') ? true : false"
        :success="!errors.first('clinicName') && userData.clinicName != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="clinic name"
        label-placeholder="Clinic Name"
        name="clinicName"
        placeholder="Clinic Name"
        v-model="userData.clinicName"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">
        {{ errors.first("clinicName") }}
      </span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('clinicAddress1') ? true : false"
        :success="
          !errors.first('clinicAddress1') && userData.clinicAddress1 != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate
        data-vv-validate-on="blur"
        data-vv-as="Clinic Address 1"
        name="clinicAddress1"
        label-placeholder="Clinic Address 1"
        placeholder="Clinic Address 1 (Optional)"
        v-model="userData.clinicAddress1"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">{{
        errors.first("clinicAddress1")
      }}</span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('city') ? true : false"
        :success="!errors.first('city') && userData.city != ''"
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="city"
        label-placeholder="City"
        name="city"
        placeholder="City"
        v-model="userData.city"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">
        {{ errors.first("city") }}
      </span>
    </div>

    <div>
      <vs-input
        :danger="errors.first('stateOrRegion') ? true : false"
        :success="
          !errors.first('stateOrRegion') && userData.stateOrRegion != ''
        "
        val-icon-success="done"
        val-icon-danger="error"
        v-validate="'required'"
        data-vv-validate-on="blur"
        data-vv-as="state or region"
        label-placeholder="State/Region"
        name="stateOrRegion"
        placeholder="State/Region"
        v-model="userData.stateOrRegion"
        class="w-full mt-6"
      />
      <span class="text-danger text-sm">
        {{ errors.first("stateOrRegion") }}
      </span>
    </div>

    <div>
      <div
        :class="{ dateClass: !errors.has('photoId') }"
        style="margin-top: 13px"
      >
        <label class="vs-input--label custom-label"
          >Attach Photo ID such as a drivers' licence or passport
          (Optional)</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.photoId">
            <div
              v-if="determineFileType(userData.photoId) === 'pdf'"
              style="margin: auto; width: 50%"
            >
              <a :href="userData.photoId" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img :src="userData.photoId" class="img-fluid" />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            id="photoIdUpload"
            :class="{ 'mt-3': userData.photoId }"
            style="text-align: center; position: relative"
          >
            <label
              id="photoId"
              :class="{ 'six-pixel': errors.has('photoId') }"
              class="image-upload photo-id-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage($event, 'photoIdUpload', 'photo-id-upload')
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("photoId") }}
      </span>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Have you ever had any restrictions imposed on your ability to
          practice?</label
        >
        <vs-radio
          vs-value="Yes"
          vs-name="radios1"
          style="margin-right: 20px"
          v-model="userData.restrictionImposed"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios1"
          v-model="userData.restrictionImposed"
          >No</vs-radio
        >
        <span
          v-if="validationKeys.showRestrictionImposeError"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Change in registration (Do you agree to tell us within 48hrs of any
          change in your registration status?)</label
        >
        <vs-radio
          vs-value="Yes"
          vs-name="radios2"
          style="margin-right: 20px"
          v-model="userData.aggreementOnAhpraRegistrationChanged"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios2"
          v-model="userData.aggreementOnAhpraRegistrationChanged"
          >No</vs-radio
        >
        <span
          v-if="validationKeys.showAggreementOnAhpraRegistrationChangedError"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Change in insurance (Do you agree to tell us within 48hrs of any
          change in your insurance?)</label
        >
        <vs-radio
          vs-value="Yes"
          vs-name="radios3"
          style="margin-right: 20px"
          v-model="userData.aggreementOnIndemnityRegistrationChanged"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios3"
          v-model="userData.aggreementOnIndemnityRegistrationChanged"
          >No</vs-radio
        >
        <span
          v-if="
            validationKeys.showAggreementOnIndemnityRegistrationChangedError
          "
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Are you an RN or an EN (If an EN, you must read the guidelines on
          supervision and acknowledge the requirements involved. Contact us for
          more detail if unsure.)</label
        >
        <vs-radio
          vs-value="RN"
          vs-name="radios4"
          style="margin-right: 20px"
          v-model="userData.rnOrEn"
          >RN</vs-radio
        >
        <vs-radio vs-value="EN" vs-name="radios4" v-model="userData.rnOrEn"
          >EN</vs-radio
        >
        <span
          v-if="validationKeys.showRnOrEnError"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >How many years of experience have you had as an RN or an EN?</label
        >
        <vs-input
          :danger="errors.first('expericedYearInRnOrEn') ? true : false"
          :success="
            !errors.first('expericedYearInRnOrEn') &&
            userData.expericedYearInRnOrEn != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required|decimal:2'"
          data-vv-validate-on="blur"
          data-vv-as="year of experience in RN or EN"
          label-placeholder=""
          name="expericedYearInRnOrEn"
          placeholder=""
          v-model="userData.expericedYearInRnOrEn"
          class="w-full"
        />
        <span class="text-danger text-sm">
          {{ errors.first("expericedYearInRnOrEn") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >In what areas/fields?</label
        >
        <vs-input
          :danger="errors.first('areasForRnOrEn') ? true : false"
          :success="
            !errors.first('areasForRnOrEn') && userData.areasForRnOrEn != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required'"
          data-vv-validate-on="blur"
          data-vv-as="area of experience"
          label-placeholder=""
          name="areasForRnOrEn"
          placeholder=""
          v-model="userData.areasForRnOrEn"
          class="w-full"
        />
        <span class="text-danger text-sm">
          {{ errors.first("areasForRnOrEn") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >For how many years have you been a cosmetic injector?</label
        >
        <vs-input
          :danger="
            errors.first('cosmeticInjectorExperienceYears') ? true : false
          "
          :success="
            !errors.first('cosmeticInjectorExperienceYears') &&
            userData.cosmeticInjectorExperienceYears != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required|decimal:2'"
          data-vv-validate-on="blur"
          data-vv-as="cosmetic injector experience year"
          label-placeholder=""
          name="cosmeticInjectorExperienceYears"
          placeholder=""
          v-model="userData.cosmeticInjectorExperienceYears"
          class="w-full"
        />
        <span class="text-danger text-sm">
          {{ errors.first("cosmeticInjectorExperienceYears") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1">
          Please list any courses in cosmetic injectables that you have
          completed. (Optional)
        </label>
        <vs-textarea
          v-model="userData.cosmeticInjectableCourses"
          v-validate="'max:255'"
          name="cosmeticInjectableCourses"
          data-vv-validate-on="blur"
          data-vv-as="nurse injectable courses"
        />
        <span class="text-danger text-sm">
          {{ errors.first("cosmeticInjectableCourses") }}
        </span>
      </div>
    </div>

    <div>
      <div
        :class="{ dateClass: !errors.has('trainingCertificate') }"
        style="margin-top: 13px"
      >
        <label class="vs-input--label custom-label"
          >Please upload any training completion certificates (Optional)</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.trainingCertificate">
            <div
              v-if="determineFileType(userData.trainingCertificate) === 'pdf'"
              style="margin: auto; width: 50%"
            >
              <a :href="userData.trainingCertificate" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img :src="userData.trainingCertificate" class="img-fluid" />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            id="trainingCertificateUpload"
            :class="{ 'mt-3': userData.trainingCertificate }"
            style="text-align: center; position: relative"
          >
            <label
              id="trainingCertificate"
              :class="{ 'six-pixel': errors.has('trainingCertificate') }"
              class="image-upload training-certificate-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage(
                    $event,
                    'trainingCertificateUpload',
                    'training-certificate-upload'
                  )
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("trainingCertificate") }}
      </span>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1">
          Please list any other teaching that you have had in injectables. (For
          instance, from another nurse? A Doctor? On-the-job training? From a
          chain clinic? Please provide some detail (names, locations,
          durations))
        </label>
        <vs-textarea
          style="margin-bottom: 10px !important"
          v-model="userData.additionalTeachingInInjectables"
          v-validate="'required|max:255'"
          name="additionalTeachingInInjectables"
          data-vv-validate-on="blur"
          data-vv-as="additional injectable teachings"
        />
        <span class="text-danger text-sm">
          {{ errors.first("additionalTeachingInInjectables") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >On average, how many hours of in-clinic cosmetic practice per week do
          you currently do?</label
        >
        <vs-input
          :danger="
            errors.first('averageHourInCosmeticPracticePerWeek') ? true : false
          "
          :success="
            !errors.first('averageHourInCosmeticPracticePerWeek') &&
            userData.averageHourInCosmeticPracticePerWeek != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required|decimal:2'"
          data-vv-validate-on="blur"
          data-vv-as="average hour in cosmetic practice per week"
          label-placeholder=""
          name="averageHourInCosmeticPracticePerWeek"
          placeholder=""
          v-model="userData.averageHourInCosmeticPracticePerWeek"
          class="w-full"
        />
        <span class="text-danger text-sm">
          {{ errors.first("averageHourInCosmeticPracticePerWeek") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >How many hours per week of in-clinic practice do you plan to
          do?</label
        >
        <vs-input
          :danger="
            errors.first('planForAverageHourInCosmeticPracticePerWeek')
              ? true
              : false
          "
          :success="
            !errors.first('planForAverageHourInCosmeticPracticePerWeek') &&
            userData.planForAverageHourInCosmeticPracticePerWeek != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required|decimal:2'"
          data-vv-validate-on="blur"
          data-vv-as="plan for desired hours in cosmetic practice per week"
          label-placeholder=""
          name="planForAverageHourInCosmeticPracticePerWeek"
          placeholder=""
          v-model="userData.planForAverageHourInCosmeticPracticePerWeek"
          class="w-full"
        />
        <span class="text-danger text-sm">
          {{ errors.first("planForAverageHourInCosmeticPracticePerWeek") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Roughly, how many patients do you think you have treated?</label
        >
        <vs-input
          :danger="errors.first('numberOfPatientsTreated') ? true : false"
          :success="
            !errors.first('numberOfPatientsTreated') &&
            userData.numberOfPatientsTreated != ''
          "
          val-icon-success="done"
          val-icon-danger="error"
          v-validate="'required|integer'"
          data-vv-validate-on="blur"
          data-vv-as="number of patients treated"
          label-placeholder=""
          name="numberOfPatientsTreated"
          placeholder=""
          v-model="userData.numberOfPatientsTreated"
          class="w-full"
        />
        <span class="text-danger text-sm">
          {{ errors.first("numberOfPatientsTreated") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <vs-row>
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
          >
            What are your confident areas for toxin treatment?
          </label>

          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment.Glabella"
              >Glabella</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment.Frontalis"
              >Frontalis</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="
                userData.confidentAreasForToxinTreatment[
                  'Lateral Orbicularis Oculi'
                ]
              "
              >Lateral Orbicularis Oculi</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment['Lip eversion']"
              >Lip eversion</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="
                userData.confidentAreasForToxinTreatment['Lipstick lines']
              "
              >Lipstick lines</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment['Brow Lift']"
              >Brow Lift</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="
                userData.confidentAreasForToxinTreatment[
                  'Nasalis (bunny lines)'
                ]
              "
              >Nasalis (bunny lines)</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="
                userData.confidentAreasForToxinTreatment['LLSAN (gummy smile)']
              "
              >LLSAN (gummy smile)</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment.Mentalis"
              >Mentalis</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox v-model="userData.confidentAreasForToxinTreatment.DAO"
              >DAO</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment.Masseters"
              >Masseters</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment.Hyperhidrosis"
              >Hyperhidrosis</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForToxinTreatment.Platysma"
              >Platysma</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <span
          v-if="validationKeys.showToxinTreatmentError"
          style="display: block"
          class="text-danger text-sm"
          >Please select at least one option.</span
        >
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <vs-row>
          <label
            style="display: block"
            class="vs-input--label custom-label mb-1"
          >
            What are your confident areas for filler treatment?
          </label>

          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForFillerTreatment['Lip filler']"
              >Lip filler</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForFillerTreatment.Chin"
              >Chin</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForFillerTreatment['Tear Trough']"
              >Tear Trough</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForFillerTreatment['Cheeks']"
              >Cheeks</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForFillerTreatment['Jawline']"
              >Jawline</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="
                userData.confidentAreasForFillerTreatment['Lip-stick lines']
              "
              >Lip-stick lines</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="userData.confidentAreasForFillerTreatment['NLF']"
              >NLF</vs-checkbox
            >
          </vs-col>
          <vs-col
            class="mb-2"
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="12"
          >
            <vs-checkbox
              v-model="
                userData.confidentAreasForFillerTreatment['Marionette Lines']
              "
              >Marionette Lines</vs-checkbox
            >
          </vs-col>
        </vs-row>
        <span
          v-if="validationKeys.showFillerTreatmentError"
          style="display: block"
          class="text-danger text-sm"
          >Please select at least one option.</span
        >
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1">
          Are there any areas that you are not confident in?
        </label>
        <vs-textarea
          style="margin-bottom: 10px !important"
          v-model="userData.areasNotConfidentIn"
          v-validate="'required|max:255'"
          name="areasNotConfidentIn"
          data-vv-validate-on="blur"
          data-vv-as="areas not confident in"
        />
        <span class="text-danger text-sm">
          {{ errors.first("areasNotConfidentIn") }}
        </span>
      </div>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1">
          Are there any other areas, products or techniques are part of your
          practice?
        </label>
        <vs-textarea
          style="margin-bottom: 10px !important"
          v-model="userData.additionalAreaOfPractice"
          v-validate="'required|max:255'"
          name="additionalAreaOfPractice"
          data-vv-validate-on="blur"
          data-vv-as="additional areas, products or techniques of practice"
        />
        <span class="text-danger text-sm">
          {{ errors.first("additionalAreaOfPractice") }}
        </span>
      </div>
    </div>

    <div>
      <div :class="{ dateClass: !errors.has('cv') }">
        <label class="vs-input--label custom-label"
          >CV (Please upload a CV with at least two references)</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.cv">
            <div
              v-if="determineFileType(userData.cv) === 'pdf'"
              style="margin: auto; width: 50%"
            >
              <a :href="userData.cv" target="_blank">Click here to view file</a>
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img :src="userData.cv" class="img-fluid" />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            id="cvUpload"
            :class="{ 'mt-3': userData.cv }"
            style="text-align: center; position: relative"
          >
            <label
              id="cvId"
              :class="{ 'six-pixel': errors.has('cv') }"
              class="image-upload cv-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="uploadImage($event, 'cvUpload', 'cv-upload')"
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("cv") }}
      </span>
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Have you completed hyalase training in the last 12 months?</label
        >
        <vs-radio
          vs-value="Yes"
          vs-name="radios5"
          style="margin-right: 20px"
          v-model="userData.hasCompletedHyalaseTraningInLastTwelveMonth"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios5"
          v-model="userData.hasCompletedHyalaseTraningInLastTwelveMonth"
          >No</vs-radio
        >
        <span
          v-if="validationKeys.showHasCompletedHyalaseTraningInLastTwelveMonth"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <div
        style="margin-top: 13px"
        :class="{
          dateClass: !errors.has('hyalaseOrComplicationManagementCertificate'),
        }"
      >
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Please upload any relevant certificates for hyalase/complication
          management (If you haven't done a course we recommend
          https://asi.braincert.com/lms/course/24241-AFI-1-0-Vascular-Complications-Emergencies#)</label
        >
        <div style="margin-top: 3px">
          <div
            class="mb-4"
            v-if="userData.hyalaseOrComplicationManagementCertificate"
          >
            <div
              v-if="
                determineFileType(
                  userData.hyalaseOrComplicationManagementCertificate
                ) === 'pdf'
              "
              style="margin: auto; width: 50%"
            >
              <a
                :href="userData.hyalaseOrComplicationManagementCertificate"
                target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img
                :src="userData.hyalaseOrComplicationManagementCertificate"
                class="img-fluid"
              />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            id="hyalaseManagenentCertificateUpload"
            :class="{
              'mt-3': userData.hyalaseOrComplicationManagementCertificate,
            }"
            style="text-align: center; position: relative"
          >
            <label
              id="hyalaseManagenentCertificateId"
              :class="{
                'six-pixel': errors.has(
                  'hyalaseOrComplicationManagementCertificate'
                ),
              }"
              class="image-upload hyalase-managenent-certificate-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage(
                    $event,
                    'hyalaseManagenentCertificateUpload',
                    'hyalase-managenent-certificate-upload'
                  )
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("hyalaseOrComplicationManagementCertificate") }}
      </span>
    </div>

    <div>
      <div
        class="w-full vs-con-input style-changer"
        :class="{
          'mt-6': !userData.hyalaseTrainingExpirationDate,
          dateClass: userData.hyalaseTrainingExpirationDate,
        }"
      >
        <label
          class="vs-input--label custom-label"
          v-if="userData.hyalaseTrainingExpirationDate"
          >Hyalase training expiration date (When does your training expire? (1
          year maximum))</label
        >
        <datepicker
          :disabled-dates="disabledDates"
          format="dd/MM/yyyy"
          v-model="userData.hyalaseTrainingExpirationDate"
          :input-class="{
            'is-true':
              !errors.has('hyalaseTrainingExpirationDate') &&
              userData.hyalaseTrainingExpirationDate,
            'is-danger': errors.has('hyalaseTrainingExpirationDate'),
          }"
          :class="{ 'six-pixel': errors.has('hyalaseTrainingExpirationDate') }"
          placeholder="Hyalase Training Expiration Date"
        >
          <template slot="afterDateInput">
            <span
              v-if="userData.hyalaseTrainingExpirationDate"
              class="bg-color-done input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >done</i
              >
            </span>
            <span
              v-if="errors.has('hyalaseTrainingExpirationDate')"
              class="bg-color-error input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >error</i
              >
            </span>
          </template>
        </datepicker>
      </div>
      <span class="text-danger text-sm">{{
        errors.first("hyalaseTrainingExpirationDate")
      }}</span>
      <input
        type="hidden"
        data-vv-as="Hyalase Training Expiration Date"
        data-vv-validate-on="change"
        name="hyalaseTrainingExpirationDate"
        v-validate="'required'"
        v-model="userData.hyalaseTrainingExpirationDate"
      />
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Have you completed Basic Life Support training (BLS) in the last 12
          months?</label
        >
        <vs-radio
          vs-value="Yes"
          vs-name="radios6"
          style="margin-right: 20px"
          v-model="
            userData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
          "
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios6"
          v-model="
            userData.hasCompletedBasicLifeSupportTrainingInLastTwelveMonth
          "
          >No</vs-radio
        >
        <span
          v-if="
            validationKeys.showHasCompletedBasicLifeSupportTrainingInLastTwelveMonth
          "
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <div
        style="margin-top: 13px"
        :class="{ dateClass: !errors.has('blsCertificate') }"
      >
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Please upload your BLS certificate</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.blsCertificate">
            <div
              v-if="determineFileType(userData.blsCertificate) === 'pdf'"
              style="margin: auto; width: 50%"
            >
              <a :href="userData.blsCertificate" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img :src="userData.blsCertificate" class="img-fluid" />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            id="blsCertificateUpload"
            :class="{ 'mt-3': userData.blsCertificate }"
            style="text-align: center; position: relative"
          >
            <label
              id="blsCertificateId"
              :class="{ 'six-pixel': errors.has('blsCertificate') }"
              class="image-upload bls-certificate-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage(
                    $event,
                    'blsCertificateUpload',
                    'bls-certificate-upload'
                  )
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("blsCertificate") }}
      </span>
    </div>

    <div>
      <div
        class="w-full vs-con-input style-changer"
        :class="{
          'mt-6': !userData.blsTrainingExpirationDate,
          dateClass: userData.blsTrainingExpirationDate,
        }"
      >
        <label
          class="vs-input--label custom-label"
          v-if="userData.blsTrainingExpirationDate"
          >BLS Training Expiration (When does your training expire? (1 year
          maximum))</label
        >
        <datepicker
          :disabled-dates="disabledDates"
          format="dd/MM/yyyy"
          v-model="userData.blsTrainingExpirationDate"
          :input-class="{
            'is-true':
              !errors.has('blsTrainingExpirationDate') &&
              userData.blsTrainingExpirationDate,
            'is-danger': errors.has('blsTrainingExpirationDate'),
          }"
          :class="{ 'six-pixel': errors.has('blsTrainingExpirationDate') }"
          placeholder="BLS Training Expiration Date"
        >
          <template slot="afterDateInput">
            <span
              v-if="userData.blsTrainingExpirationDate"
              class="bg-color-done input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >done</i
              >
            </span>
            <span
              v-if="errors.has('blsTrainingExpirationDate')"
              class="bg-color-error input-icon-validate vs-input--icon-validate"
            >
              <i
                valiconpack="material-icons"
                class="vs-icon notranslate icon-scale material-icons null"
                >error</i
              >
            </span>
          </template>
        </datepicker>
      </div>
      <span class="text-danger text-sm">{{
        errors.first("blsTrainingExpirationDate")
      }}</span>
      <input
        type="hidden"
        data-vv-as="BLS Training Expiration Date"
        data-vv-validate-on="change"
        name="blsTrainingExpirationDate"
        v-validate="'required'"
        v-model="userData.blsTrainingExpirationDate"
      />
    </div>

    <div>
      <div style="margin-top: 13px">
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Has immunity to Hepatitis B</label
        >
        <vs-radio
          vs-value="Yes"
          vs-name="radios8"
          style="margin-right: 20px"
          v-model="userData.hasImmunityToHepatitisB"
          >Yes</vs-radio
        >
        <vs-radio
          vs-value="No"
          vs-name="radios8"
          v-model="userData.hasImmunityToHepatitisB"
          >No</vs-radio
        >
        <span
          v-if="validationKeys.showhasImmunityToHepatitisBError"
          style="display: block"
          class="text-danger text-sm"
          >Please select one option.</span
        >
      </div>
    </div>

    <div>
      <div
        style="margin-top: 13px"
        :class="{ dateClass: !errors.has('vaccinationCertificate') }"
      >
        <label style="display: block" class="vs-input--label custom-label mb-1"
          >Please upload your vaccination certification (We need to see evidence
          of Hepatitis B immunity or vaccination)</label
        >
        <div style="margin-top: 3px">
          <div class="mb-4" v-if="userData.vaccinationCertificate">
            <div
              v-if="
                determineFileType(userData.vaccinationCertificate) === 'pdf'
              "
              style="margin: auto; width: 50%"
            >
              <a :href="userData.vaccinationCertificate" target="_blank"
                >Click here to view file</a
              >
            </div>
            <div v-else style="margin: auto" class="register-img-wrapper">
              <img :src="userData.vaccinationCertificate" class="img-fluid" />
            </div>
            <!-- <div v-else style="margin:auto;height:160px;width:50%;">
            <img :src="userData.insuranceCertificate" width="100%" height="100%" />
          </div>-->
          </div>
          <div
            id="vaccinationCertificateUpload"
            :class="{ 'mt-3': userData.vaccinationCertificate }"
            style="text-align: center; position: relative"
          >
            <label
              id="vaccinationCertificateId"
              :class="{ 'six-pixel': errors.has('vaccinationCertificate') }"
              class="image-upload vaccination-certificate-upload"
              style="
                border: 1px solid #cccccc;
                display: inline-block;
                width: 100%;
                border-radius: 5px;
                padding: 10px;
              "
            >
              + Attach Document
              <input
                type="file"
                @change="
                  uploadImage(
                    $event,
                    'vaccinationCertificateUpload',
                    'vaccination-certificate-upload'
                  )
                "
                id="file-input"
                hidden
              />
            </label>
          </div>
        </div>
      </div>
      <span class="text-danger text-sm">
        {{ errors.first("vaccinationCertificate") }}
      </span>
    </div>

    <div style="margin-top: 13px">
      <label style="display: block" class="vs-input--label custom-label mb-1"
        >Have you read and agree to adhere to the Fresh Clinics "Clinical
        Standards" document?</label
      >
      <vs-radio
        vs-value="Yes"
        vs-name="radios7"
        style="margin-right: 20px"
        v-model="userData.hasReadClinicalStandardsDocuments"
        >Yes</vs-radio
      >
      <vs-radio
        vs-value="No"
        vs-name="radios7"
        v-model="userData.hasReadClinicalStandardsDocuments"
        >No</vs-radio
      >
      <span
        v-if="validationKeys.showHasReadClinicalStandardsDocumentsError"
        style="display: block"
        class="text-danger text-sm"
        >Please select one option.</span
      >
    </div>

    <div style="margin-top: 13px">
      <label style="display: block" class="vs-input--label custom-label mb-1"
        >I confirm that I have made a true and accurate declaration.</label
      >
      <vs-radio
        vs-value="Yes"
        vs-name="radios8"
        style="margin-right: 20px"
        v-model="userData.hasConfirmedDeclarations"
        >Yes</vs-radio
      >
      <vs-radio
        vs-value="No"
        vs-name="radios8"
        v-model="userData.hasConfirmedDeclarations"
        >No</vs-radio
      >
      <span
        v-if="validationKeys.showHasConfirmedDeclarationsError"
        style="display: block"
        class="text-danger text-sm"
        >Please select one option.</span
      >
    </div>

    <div style="margin-top: 13px">
      <label style="display: block" class="vs-input--label custom-label mb-1"
        >Are you happy for us to email you?</label
      >
      <vs-radio
        vs-value="Yes"
        vs-name="radios9"
        style="margin-right: 20px"
        v-model="userData.canSendEmail"
        >Yes</vs-radio
      >
      <vs-radio vs-value="No" vs-name="radios9" v-model="userData.canSendEmail"
        >No</vs-radio
      >
      <span
        v-if="validationKeys.showCanSendEmailError"
        style="display: block"
        class="text-danger text-sm"
        >Please select one option.</span
      >
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import Datepicker from "vuejs-datepicker";

export default {
  name: "NurseRegister",
  props: ["userData", "validationKeys"],
  components: {
    Datepicker,
  },
  data() {
    return {
      flashMessage: "",
      showDismissibleAlert: false,
      disabledDates: { to: new Date() },
    };
  },
  methods: {
    uploadImage(event, key = "profileImage", loadingClass = "") {
      this.$vs.loading({
        background: "#fff",
        color: "#626262",
        container: `.${loadingClass}`,
        scale: 0.45,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.userData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            document.getElementById("ahpraId").style.borderColor = "#cccccc";
            self.userData.ahpraCertificate = res.data.Location;
            this.errors.remove("aphraCertificate");
          }
          if (key === "insuranceUpload") {
            this.errors.remove("insuranceCertificate");
            document.getElementById("insuranceId").style.borderColor =
              "#cccccc";
            self.userData.insuranceCertificate = res.data.Location;
          }
          if (key === "photoIdUpload") {
            this.errors.remove("photoId");
            document.getElementById("photoId").style.borderColor = "#cccccc";
            self.userData.photoId = res.data.Location;
          }
          if (key === "trainingCertificateUpload") {
            this.errors.remove("trainingCertificate");
            document.getElementById("trainingCertificate").style.borderColor =
              "#cccccc";
            self.userData.trainingCertificate = res.data.Location;
          }
          if (key === "cvUpload") {
            this.errors.remove("cv");
            document.getElementById("cvId").style.borderColor = "#cccccc";
            self.userData.cv = res.data.Location;
          }
          if (key === "hyalaseManagenentCertificateUpload") {
            this.errors.remove("hyalaseOrComplicationManagementCertificate");
            document.getElementById(
              "hyalaseManagenentCertificateId"
            ).style.borderColor = "#cccccc";
            self.userData.hyalaseOrComplicationManagementCertificate =
              res.data.Location;
          }
          if (key === "blsCertificateUpload") {
            this.errors.remove("blsCertificate");
            document.getElementById("blsCertificateId").style.borderColor =
              "#cccccc";
            self.userData.blsCertificate = res.data.Location;
          }
          if (key === "vaccinationCertificateUpload") {
            this.errors.remove("vaccinationCertificate");
            document.getElementById(
              "vaccinationCertificateId"
            ).style.borderColor = "#cccccc";
            self.userData.vaccinationCertificate = res.data.Location;
          }
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
        })
        .catch((err) => {
          this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
};
</script>

<style lang="scss">
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
.is-danger {
  border: 1px solid #ea5455 !important;
}
.is-true {
  border: 1px solid #28c76f !important;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}

.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}

.image-upload {
  cursor: pointer;
}
.vdp-datepicker {
  margin-top: 3px;
}
.tmargin {
  margin-top: -5px;
}
.six-pixel {
  margin-bottom: 6px;
}
.avatar {
  border-radius: 50%;
}
</style>
